import { useState, useContext } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Room, Call, OpenInNew, Edit, Delete, Star } from "@mui/icons-material";
import { DashboardContext } from "./../../context/DashboardContext";

const DashboardCardItem = ({ item, handleDeleteItem, isAPIRunning }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [imgError, setImgError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selectedSection, setSelectedSection } = useContext(DashboardContext);

  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation();
    navigate(`/dashboard/createlisting/update?id=${id}&type=listing`);
    setSelectedSection(`/dashboard/createlisting/update?id=${id}`);
  };

  const truncateDescription = (description, maxLength) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = description;
    let plainText = tempElement.textContent || tempElement.innerText || "";
    plainText = plainText.trim();
    if (plainText.length > maxLength) {
      plainText = plainText.substring(0, maxLength) + "...";
    }

    return plainText;
  };

  const truncateTitle = (title, maxLength) => {
    if (title && title.length > maxLength) {
      return title.substring(0, maxLength) + "..";
    }
    return title;
  };

  const urlTitle = (website, maxLength) => {
    if (website && website.length > maxLength) {
      return website.substring(0, maxLength) + "..";
    }
    return website;
  };

  const truncateAddress = (address, maxLength) => {
    if (address && address.length > maxLength) {
      return address.substring(0, maxLength) + "..";
    }
    return address;
  };

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = (id) => {
    handleDeleteItem(id);
    setShowDeleteModal(false);
  };

  const handleImageError = () => {
    setImgError(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const shouldHide = item.website === "https://www.example.com/";

  return (
    <>
      <Grid
        item
        xs={12}
        sm={5.4}
        md={3.7}
        lg={3.8}
        sx={{
          padding: { xs: "5px", sm: "0px", md: "10px" },
          marginLeft: "13px",
        }}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              width: { lg: "auto", md: "110%", sm: "110%", xs: "105%" },
              pb: 0,
              position: "relative",
              transition: "transform 0.3s",
              borderRadius: "6px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              "&:hover": {
                "& Button": {
                  color: "white",
                  opacity: 0.9,
                },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                opacity: 1,
                transition: "opacity 0.3s",
              }}
            ></Box>

            <CardMedia
              component="img"
              height="240"
              width="110%"
              image={
                !imgError && item.image && isAPIRunning
                  ? `${backendUrl}/${item.businessBanner}`
                  : `${item.businessBanner}`
              }
              alt={item.title}
              onError={handleImageError}
            />
            <Grid
              container
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "right",
                gap: 1,
                top: "10px",
                right: "20px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  border: "1px solid #fff",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  backgroundColor: "#1c9ac0",
                }}
                onClick={(e) => handleEditClick(e, item.id)}
              >
                <Edit
                  sx={{
                    color: "#fff",
                    display: "flex",
                    margin: "auto",
                    position: "relative",
                    fontSize: "24px",
                    top: "3px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  borderRadius: "50%",
                  border: "1px solid #fff",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  backgroundColor: "#1c9ac0",
                }}
                onClick={(e) => handleDeleteClick(e, item.id)}
              >
                <Delete
                  style={{
                    color: "#fff",
                    display: "flex",
                    margin: "auto",
                    position: "relative",
                    fontSize: "24px",
                    top: "3px",
                  }}
                />
              </Box>
            </Grid>
            <Typography
              variant="span"
              sx={{
                fontWeight: "bold",
                letterSpacing: "1px",
                lineHeight: "2.4rem",
                position: "absolute",
                background: "white",
                borderRadius: "28px",
                left: "11px",
                top: "14px",
                padding: "0px 17px",
              }}
            >
              {item.listFor}
            </Typography>
            <Link
              to={`/listings/${item.id}?type=listing`}
              style={{ textDecoration: "none" }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  pb: 1,
                  width: "100%",
                  position: "relative",
                }}
              >
                <Box px={2}>
                  <Box
                    sx={{
                      display: { xs: "flex", sm: "flex" },
                      justifyContent: { xs: "space-between" },
                    }}
                  >
                    <img
                      style={{
                        width: "63px",
                        height: "63px",
                        borderRadius: "50%",
                        border: "4px solid white",
                        position: "absolute",
                        top: "-34px",
                        left: "38px",
                      }}
                      src={
                        !imgError && item.businessLogo && isAPIRunning
                          ? `${backendUrl}/${item.businessLogo}`
                          : `${item.businessLogo}`
                      }
                      alt={item.title}
                      onError={handleImageError}
                    />
                    <Box
                      className="rating"
                      sx={{
                        display: "flex",
                        gap: 1,
                        position: "absolute",
                        right: "20px",
                        top: "-36px",
                        px: "25px",
                      }}
                    >
                      <Box sx={{ color: "#ff9e00" }}>
                        <Star sx={{ fontSize: "19px" }} />
                        <Star sx={{ fontSize: "19px" }} />
                        <Star sx={{ fontSize: "19px" }} />
                        <Star sx={{ fontSize: "19px" }} />
                        <Star sx={{ fontSize: "19px" }} />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          color: "white",
                        }}
                      >
                        5 / <span style={{ color: "#ffffffbd" }}> 5</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Link
                    to={`/listings/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "700",
                        letterSpacing: "1px",
                        lineHeight: "26px",
                        textAlign: "left",
                        textDecoration: "none",
                        cursor: "pointer",
                        marginTop: "10px",
                        fontFamily: "poppins",
                        fontSize: "18px",
                        marginBottom: "5px",
                        marginTop: "30px",
                        color: "black",
                        "&:hover": { color: "#1c9ac0" },
                      }}
                    >
                      {truncateTitle(item.title, 30)}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      fontWeight: "500",
                      letterSpacing: "1px",
                      lineHeight: "23px",
                      textAlign: "left",
                      marginTop: "10px",
                      fontFamily: "poppins",
                      fontSize: "15px",
                      marginBottom: "10px",
                      color: "#4a4a4a",
                    }}
                  >
                    {truncateDescription(item.desc, 30)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#4a4a4a",
                      textAlign: "left",
                      fontSize: { xs: "15px", sm: "16px" },
                      marginBottom: "5px",
                    }}
                  >
                    <span>
                      <Room
                        style={{
                          fontSize: "25px",
                          position: "relative",
                          top: "4px",
                          color: "#1c9ac0",
                          top: "5px",
                          marginRight: "8px",
                        }}
                      />
                    </span>{" "}
                    {truncateTitle(item.address, 30)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#4a4a4a",
                      textAlign: "left",
                      fontSize: { xs: "15px", sm: "16px" },
                      marginBottom: "5px",
                    }}
                  >
                    <span>
                      <Call
                        style={{
                          fontSize: "25px",
                          position: "relative",
                          top: "4px",
                          color: "#1c9ac0",
                          top: "5px",
                          marginRight: "8px",
                        }}
                      />
                    </span>{" "}
                    <Link
                      onClick={handleLinkClick}
                      to={`tel:${item.phone}`}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      {" "}
                      {item.phone}
                    </Link>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#4a4a4a",
                      textAlign: "left",
                      fontSize: { xs: "15px", sm: "16px" },
                      marginBottom: "5px",
                    }}
                  >
                    <span>
                      <OpenInNew
                        style={{
                          fontSize: "25px",
                          position: "relative",
                          top: "4px",
                          color: "#1c9ac0",
                          top: "5px",
                          marginRight: "8px",
                        }}
                      />
                    </span>{" "}
                    {!shouldHide ? (
                      <Link
                        onClick={handleLinkClick}
                        to={`${item.website}`}
                        style={{ textDecoration: "none", color: "unset" }}
                      >
                        {" "}
                        {urlTitle(item.website, 30)}
                      </Link>
                    ) : (
                      <Link
                        onClick={handleLinkClick}
                        to={shouldHide ? "#" : "#"}
                        style={{ textDecoration: "none", color: "unset" }}
                      >
                        -
                      </Link>
                    )}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    ></Box>
                  </Box>
                </Box>
              </CardContent>
            </Link>
          </Card>
        </Box>
      </Grid>

      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            bgcolor: "#fff",
            width: "300px",
            p: 4,
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
        >
          <Typography
            variant="h6"
            id="delete-modal-title"
            sx={{ color: "black" }}
          >
            Are you sure you want to delete this item?
          </Typography>
          <Box mt={2} sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteConfirm(item.id)}
              sx={{ mr: 1.5 }}
            >
              Delete
            </Button>
            <Button variant="outlined" onClick={handleCancelDelete}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DashboardCardItem;
