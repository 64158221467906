import React from "react";
import { Grid, Typography, Stack, styled } from "@mui/material";
import Image from "../../assets/images/your-background-image.jpeg";
import { keyframes } from "@emotion/react";
import Arrow from "../../assets/images/line-arrow.png";
import HomeSearch from "../Home/HomeSearch";

const fadeInBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledHeader = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  minHeight: "90vh",
  display: "flex",
  alignItems: "center",
  animation: `${fadeInBottom} 3s ease`,
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: "0.4",
  },
  [theme.breakpoints.down("sm")]: {
    backgroundPosition: "center",
    minHeight: "70vh",
  },
}));
export const StyledHeaderItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: { xs: "center", md: "flex-start" },
  flexDirection: "column",
  zIndex: 1,
  color: "#fff",
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "46px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));
export const StyledSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Sacramento",
  fontSize: "70px",
  fontWeight: 400,
  textTransform: "none",
  position: "relative",
  zIndex: 1,
  lineHeight: 1,
  color: "#1c9ac0",
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));
export const CategoryHeading = styled(Typography)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  marginLeft: "auto",
  marginRight: "auto",
  fontFamily: "Sacramento",
  fontSize: "20px",
  fontWeight: 400,
  textTransform: "none",
  lineHeight: 1,
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
  "&&::before": {
    content: '""',
    width: "64px",
    height: "110px",
    position: "absolute",
    bottom: "90%",
    left: "-85px",
    background: `url(${Arrow}) no-repeat center center transparent`,
    backgroundSize: "contain",
    zIndex: -1,
  },
}));

const StyledHader = () => {
  return (
    <>
      <Grid container>
        <StyledHeader item xs={12} mt={{ xs: 0, md: 0 }}>
          <StyledHeaderItem
            item
            px={{ xs: 4, md: 12 }}
            pt={{ xs: 22 }}
            pb={{ xs: 8 }}
            ml="auto"
            mr="auto"
          >
            <StyledSubHeading
              sx={{
                fontSize: { xs: "30px", sm: "68px" },
                lineHeight: { xs: "3rem", sm: "5rem" },
                textAlign: { xs: "center" },
              }}
            >
              Explore the
            </StyledSubHeading>
            <StyledHeading
              sx={{
                fontSize: { xs: "30px", sm: "80px" },
                lineHeight: { xs: "3rem", sm: "5rem" },
                textAlign: { xs: "center" },
                fontWeight: 800,
                fontFamily: "Barlow",
              }}
            >
              {" "}
              Best in Your City
            </StyledHeading>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: "12px", sm: "16px" },
                textAlign: { xs: "center" },
                fontWeight: 400,
                marginY: "8px",
                wordWrap: "break-word",
                px: { sm: "10rem" },
                pt: { sm: "2rem" },
              }}
            >
              Discover top-rated local businesses, from restaurants and
              nightlife to home services and professionals, right in your
              neighborhood with our business listings.
            </Typography>
            <HomeSearch />

            <Stack alignItems={{ xs: "center", md: "flex-start" }}>
              <CategoryHeading
                sx={{
                  fontSize: { xs: "30px", sm: "40px" },
                  lineHeight: { xs: "3rem", sm: "5rem" },
                  textAlign: { xs: "center" },
                  mt: { xs: "4rem", sm: "10rem" },
                }}
              >
                Dive into Popular Categories{" "}
              </CategoryHeading>
            </Stack>
          </StyledHeaderItem>
        </StyledHeader>
      </Grid>
    </>
  );
};

export default StyledHader;
