import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import LabelWithInput from "./LabelWithInput";
import ButtonPrimary from "./ButtonPrimary";

const ResetPasswordForm = ({ handleOpenModal }) => {
  const [userName, setusername] = useState("");

  const handleUserName = (e) => {
    setusername(e.target.value);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Barlow",
          fontSize: "22px",
          color: "rgb(22, 28, 38)",
          fontWeight: "600",
          textAlign: "center",
          position: "relative",
        }}
      >
        Reset Password
        <span
          style={{
            content: '""',
            width: "50px",
            height: "2px",
            background: "#1c9ac0",
            position: "absolute",
            bottom: "-4px",
            left: "50%",
            marginLeft: "-25px",
            zIndex: 1,
          }}
        />
      </Typography>
      <Box>
        <LabelWithInput
          label="Username or E-mail"
          id="name"
          value={userName}
          placeholder="Username"
          type="text"
          onChange={handleUserName}
        />

        <ButtonPrimary
          sx={{
            background: "#1c9ac0",
            color: "white",
            height: "64px",
            px: 6,
            marginTop: "19px",
            borderRadius: 1,
            letterSpacing: "3px",
            fontFamily: "Barlow",
            fontSize: "14px",
            fontWeight: "600",
            "&:hover": {
              background: "#1c9ac0",
              color: "white",
              opacity: 0.9,
            },
          }}
        >
          GET NEW PASSWORD
        </ButtonPrimary>
        <Box
          sx={{
            color: "#1c9ac0",
            fontSize: "16px",
            lineHeight: "29px",
            fontWeight: 400,
            fontFamily: "sans-serif",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "18px",
          }}
        >
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              <span style={{ color: "rgb(121, 127, 137)" }}>
                {" "}
                Don't have an account
              </span>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal("register")}
              >
                {" "}
                Register
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordForm;
