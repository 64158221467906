import React from "react";
import { Button } from "@mui/material";
const ButtonPrimary = (props) => {
  const { children, sx, ...otherProps } = props;
  return (
    <Button
      fullWidth
      sx={{
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default ButtonPrimary;
