import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import CheckboxFilter from "./CheckboxFilter";
import axios from "axios";

const SideFilter = ({
  accNTaxService,
  setAccNTaxService,
  area,
  setArea,
  setItems,
  items,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    const updatedServices = checked
      ? [...accNTaxService, name]
      : accNTaxService.filter((service) => service !== name);

    try {
      setLoading(true);
      setError(false);

      const searchParams = {
        accountingAndTaxService: updatedServices,
      };

      const response = await axios.get(`${backendUrl}/api/places`, {
        params: {
          search: JSON.stringify(searchParams),
        },
      });
      setItems(response.data.places);
      setAccNTaxService(updatedServices);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChangeArea = async (event) => {
    const { name, checked } = event.target;
    const updatedAreas = checked
      ? [...area, name]
      : area.filter((selectedArea) => selectedArea !== name);

    try {
      setLoading(true);
      setError(false);

      const searchParams = {
        area: updatedAreas.length > 0 ? updatedAreas : undefined,
      };

      const response = await axios.get(`${backendUrl}/api/places`, {
        params: {
          search: JSON.stringify(searchParams),
        },
      });
      setItems(response.data.places);
      setArea(updatedAreas);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const uniqueAreas = [...new Set(items.map((item) => item.area))].map(
    (areaName) => areaName.substring(0, 7)
  );

  return (
    <>
      <Grid container spacing={2} sx={{ width: "auto" }}>
        <Grid item xs={12} sm={6} md={12}>
          <Box sx={{ paddingLeft: { xs: 2, sm: 0 } }}>
            <Typography
              variant="h5"
              sx={{
                px: {
                  textAlign: "left",
                  fontWeight: 600,
                  lineHeight: "22px",
                  fontSize: "20px",
                  color: "#2d3954",
                  fontFamily: "poppins",
                  marginBottom: "10px",
                },
              }}
            >
              Categories
            </Typography>
            <CheckboxFilter
              label="Accounting and Bookkeeping"
              checked={accNTaxService.includes("Accounting and Bookkeeping")}
              onChange={handleCheckboxChange}
              name="Accounting and Bookkeeping"
            />
            <CheckboxFilter
              label="Marketing and Advertising"
              checked={accNTaxService.includes("Marketing and Advertising")}
              onChange={handleCheckboxChange}
              name="Marketing and Advertising"
            />
            <CheckboxFilter
              label="IT and Technical Support"
              checked={accNTaxService.includes("IT and Technical Support")}
              onChange={handleCheckboxChange}
              name="IT and Technical Support"
            />
            <CheckboxFilter
              label="Consulting Services"
              checked={accNTaxService.includes("Consulting Services")}
              onChange={handleCheckboxChange}
              name="Consulting Services"
            />
            <CheckboxFilter
              label="Legal Services"
              checked={accNTaxService.includes("Legal Services")}
              onChange={handleCheckboxChange}
              name="Legal Services"
            />
            <CheckboxFilter
              label="Human Resources and Recruitment"
              checked={accNTaxService.includes(
                "Human Resources and Recruitment"
              )}
              onChange={handleCheckboxChange}
              name="Human Resources and Recruitment"
            />
            <CheckboxFilter
              label="Financial Planning and Advisory"
              checked={accNTaxService.includes(
                "Financial Planning and Advisory"
              )}
              onChange={handleCheckboxChange}
              name="Financial Planning and Advisory"
            />
            <CheckboxFilter
              label="Cleaning and Maintenance"
              checked={accNTaxService.includes("Cleaning and Maintenance")}
              onChange={handleCheckboxChange}
              name="Cleaning and Maintenance"
            />
            <CheckboxFilter
              label="Others"
              checked={accNTaxService.includes("Others")}
              onChange={handleCheckboxChange}
              name="Others"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SideFilter;
