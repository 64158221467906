import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  Button,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HomeSearch = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [matchingTitles, setMatchingTitles] = useState([]);
  const [location, setLocation] = useState("");
  const [selectRegion, setSelectedRegion] = useState("");
  useEffect(() => {
    setSelectedOption("All Categories");
    setSelectedRegion("All Regions");
  }, []);

  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleRegionOptionSelect = (event) => {
    setSelectedRegion(event.target.value);
  };
  const handleKeyUp = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const matches = data.filter(
      (item) =>
        item.intro &&
        item.intro.toLowerCase().includes(inputValue) &&
        inputValue.length > 0
    );
    setMatchingTitles(
      matches.map((match) => ({
        title: match.title,
        slug: match.slug,
        location: match.location || "",
      }))
    );
  };

  const getInputData = () => {
    const categoryData = selectedOption;
    const regionCategory = selectRegion;
    const queryString = `?region=${encodeURIComponent(
      regionCategory
    )}&category=${encodeURIComponent(categoryData)}`;

    const newUrl = "/listings" + queryString;
    navigate(newUrl, { state: { categoryData, regionCategory } });
  };
  const options = [
    "All Categories",
    "Automotive Services",
    "Beauty",
    "Fitness",
    "House",
    "Home Decor",
    "Locksmiths",
    "Nightlife",
    "Plumbers",
    "Restaurants",
    "Shopping",
  ];

  const regionOptions = [
    "All Regions",
    "Canada",
    "United Kingdom",
    "United States",
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(`${backendUrl}/api/places`);
      setData(response.data.places);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Box>
        <Container
          maxWidth="lg"
          sx={{ px: { xs: "0rem", sm: "3rem", lg: "9rem" }, pt: 4 }}
        >
          <Grid spacing={1} container>
            <Grid item xs={12} md={5}>
              <Box
                component="section"
                sx={{
                  position: "relative",
                  bgcolor: "#fff",
                  borderRadius: "4px",
                  pl: { sm: "20px", md: "20px", xs: "20px" },
                }}
              >
                <input
                  className="lookingFor"
                  type="text"
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    height: "62px",
                    borderRadius: "4px",
                    fontSize: "17px",
                    color: "#797f89",
                    paddingLeft: "-20px",
                  }}
                  placeholder="What are you looking for?"
                  onKeyUp={handleKeyUp}
                />
                {matchingTitles.length > 0 && (
                  <div
                    style={{
                      marginTop: "5px",
                      background: "white",
                      borderRadius: "4px",
                      padding: "10px 0px",
                      position: "absolute",
                      zIndex: 999,
                      width: "100%",
                      boxShadow: "0 5px 10px rgba(0,0,0,.1)",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    {matchingTitles.map((match, index) => (
                      <div
                        key={index}
                        style={{
                          borderBottom:
                            index !== matchingTitles.length - 1
                              ? "1px solid rgba(0,0,0,.1)"
                              : "none",
                          padding: "10px 20px",
                        }}
                      >
                        <Link
                          to={`/listings/${match.slug}`}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              fontFamily: "Barlow ",
                              color: "#161c26",
                              fontSize: "17px",
                              position: "relative",
                              fontWeight: 600,
                              textAlign: "left",
                              lineHeight: "22px",
                              borderLeft: "0.2px solid black",
                            }}
                          >
                            {match.title}{" "}
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{
                              fontFamily: "Barlow ",
                              color: "#797f89",
                              fontSize: "16px",
                              position: "relative",
                              fontWeight: 500,
                              textAlign: "left",
                              lineHeight: "22px",
                            }}
                          >
                            {match.location && -`${match.location}`}
                          </Typography>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ pr: { md: 0, sm: 4.5, xs: 4.5 } }}>
              <Box
                component="section"
                sx={{
                  width: { sm: "106%", xs: "111%", md: "100%" },
                  display: "flex",
                  margin: "auto",
                  marginTop: { xs: "0" },
                }}
              >
                <FormControl fullWidth>
                  <Select
                    placeholder="All Regions"
                    value={selectRegion || ""}
                    onChange={handleRegionOptionSelect}
                    sx={{
                      width: { md: "100%", sm: "101%", xs: "101%" },
                      border: "none",
                      outline: "none",
                      height: "64px",
                      borderRadius: "4px",
                      fontSize: "17px",
                      color: "#797f89",
                      padding: "0px 20px",
                      background: "white",
                      textAlign: { xs: "left", md: "left" },
                    }}
                  >
                    {regionOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} order={{ xs: 4, md: 3 }}>
              <Box component="section">
                <Button
                  onClick={getInputData}
                  sx={{
                    zIndex: "99",
                    background: "#1c9ac0",
                    color: "white",
                    ml: { xs: "0px", md: "0px" },
                    height: "64px",
                    px: 6,
                    borderRadius: 3,
                    width: "auto",
                    "&:hover": {
                      background: "#1c9ac0",
                      color: "white",
                      opacity: 0.9,
                    },
                  }}
                >
                  <span
                    style={{ position: "relative", top: "4px", left: "-5px" }}
                  >
                    <SearchIcon />
                  </span>
                  Search
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={10} order={{ xs: 3, md: 4 }}>
              <Box
                component="section"
                sx={{
                  width: { xs: "100%", md: "63%" },
                  display: "flex",
                  margin: "auto",
                  marginTop: { xs: "0", md: "6px" },
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <Select
                    placeholder="All Categories"
                    value={selectedOption || ""}
                    onChange={handleOptionSelect}
                    sx={{
                      width: { md: "100%", sm: "101%", xs: "101%" },
                      border: "none",
                      outline: "none",
                      height: "64px",
                      borderRadius: "4px",
                      fontSize: "17px",
                      color: "#797f89",
                      padding: "0px 20px",
                      background: "white",
                    }}
                  >
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HomeSearch;
