import { Grid, Stack, Typography, styled, keyframes } from "@mui/material";
import React from "react";
import BlogHeaderPic from "../../assets/images/BlogHeader.avif";
import { Link } from "react-router-dom";

const fadeInBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledHeader = styled(Grid)(({ theme }) => ({
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  minHeight: "64vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${fadeInBottom} 3s ease`,
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))", // Gradient overlay
    zIndex: 1,
  },
  [theme.breakpoints.down("sm")]: {
    backgroundPosition: "center",
    minHeight: "50vh",
  },
}));

const StyledHeaderItem = styled(Grid)(() => ({
  zIndex: 2,
  color: "#fff",
}));

const PageHeader = ({ image = BlogHeaderPic, page = "Latest Posts" }) => {
  return (
    <StyledHeader
      item
      xs={12}
      mt={{ xs: 0, md: 0 }}
      sx={{ backgroundImage: `url(${image})`, backgroundPosition: "bottom" }}
    >
      <StyledHeaderItem item px={{ xs: 4, sm: 8 }}>
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "36px" },
            fontWeight: "bold",
            lineHeight: "3rem",
            mt: 5,
            textTransform: "uppercase",
          }}
        >
          {page}
        </Typography>

        <Stack direction="row" sx={{ justifyContent: "center" }}>
          <Typography
            component={Link}
            to="/"
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              marginY: "6px",
              ml: 0.5,
              textDecoration: "none",
              color: "#1c9ac0",
            }}
          >
            Home
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              marginY: "6px",
              ml: 0.6,
            }}
          >
            {` - ${page}`}
          </Typography>
        </Stack>
      </StyledHeaderItem>
    </StyledHeader>
  );
};

export default PageHeader;
