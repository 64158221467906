import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardSidebar from "../components/Dashboard/DashboardSideBar";
import DashboardNavBar from "../components/Dashboard/DashboardNavBar";
import CreateUser from "../components/Dashboard/CreateUser";
import DashboardListing from "../components/Dashboard/DashboardListing";
import CreateListing from "../components/Dashboard/CreateListing";
import { DashboardContext } from "../context/DashboardContext";
import DashboardPending from "../components/Dashboard/pending/DashboardPending";

const DashBoard = () => {
  const [triggerResetForm, setTriggerResetForm] = useState(false);
  const { selectedSection } = useContext(DashboardContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const renderSection = () => {
    switch (selectedSection) {
      case "/dashboard/createuser":
        return <CreateUser />;
      case "/dashboard/createlisting":
        return (
          <CreateListing
            triggerResetForm={triggerResetForm}
            setTriggerResetForm={setTriggerResetForm}
          />
        );
      case `/dashboard/createlisting/update?id=${id}`:
        return <CreateListing id={id} />;
      case "/dashboard/listings":
        return <DashboardListing />;
      case "/dashboard/pendings":
        return <DashboardPending />;
      default:
        return <CreateListing id={id} />;
    }
  };
  return (
    <Grid sx={{ display: "flex", height: "100vh" }}>
      <DashboardSidebar setTriggerResetForm={setTriggerResetForm} />
      <Grid sx={{ display: "block", width: "100%", overflowY: "hidden" }}>
        <DashboardNavBar />
        {renderSection()}
      </Grid>
    </Grid>
  );
};

export default DashBoard;
