import React, { useState, useContext, useRef, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import LabelWithInput from "./LabelWithInput";
import ButtonPrimary from "./ButtonPrimary";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router-dom";
import Pfp from "../../assets/images/userImg.png";

const RegisterForm = ({ handleOpenModal, setIsModalOpen }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [registerUserName, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmRegisterPassword, setConfirmRegisterPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetch(Pfp)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "Pfptemp.jpg", { type: blob.type });
        setSelectedImage(file);
      });
  }, []);

  const validateUsername = (username) => {
    if (!username) {
      setUsernameError("Username is required");
      return false;
    }
    setUsernameError("");
    return true;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = (password) => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password");
      return false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const handleRegisterUserName = (e) => {
    setRegisterUsername(e.target.value);
  };
  const handleRegisterEmail = (e) => {
    setRegisterEmail(e.target.value);
  };
  const handleUserPassword = (e) => {
    setRegisterPassword(e.target.value);
  };
  const handleConfirmUserPassword = (e) => {
    setConfirmRegisterPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Start loading

    const isUsernameValid = validateUsername(registerUserName);
    const isEmailValid = validateEmail(registerEmail);
    const isPasswordValid = validatePassword(registerPassword);
    const isConfirmPasswordValid = validateConfirmPassword(
      registerPassword,
      confirmRegisterPassword
    );

    if (
      !isUsernameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      !isConfirmPasswordValid
    ) {
      setIsLoading(false); // Stop loading if validation fails
      return;
    }

    const formData = new FormData();
    formData.append("username", registerUserName);
    formData.append("email", registerEmail);
    formData.append("password", registerPassword);
    formData.append("role", 0);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/signup`, {
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();
      console.log("responseData", responseData);
      if (response.ok) {
        auth.login(
          responseData.user.id,
          responseData.user.userRole,
          responseData.user.token,
          responseData.user.email
        );
        if (responseData.user.userRole == 1) {
          navigate("/dashboard/createuser");
        } else {
          navigate("/dashboard/createlisting");
        }
        setIsModalOpen(false);
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (err) {
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        pt: { xs: 22, md: 0 },
        pb: { xs: 10, md: 0 },
        px: { xs: 0, sm: "20%", md: 0 },
      }}
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Barlow",
          fontSize: "22px",
          color: "rgb(22, 28, 38)",
          fontWeight: "600",
          textAlign: "center",
          position: "relative",
        }}
      >
        Register
        <span
          style={{
            content: '""',
            width: "50px",
            height: "2px",
            background: "#1c9ac0",
            position: "absolute",
            bottom: "-4px",
            left: "50%",
            marginLeft: "-25px",
            zIndex: 1,
          }}
        />
      </Typography>
      <Box sx={{ px: "30px" }}>
        <LabelWithInput
          label="Name"
          id="name"
          value={registerUserName}
          placeholder="Name"
          type="text"
          onChange={handleRegisterUserName}
        />
        {usernameError && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: 1 }}
          >
            {usernameError}
          </Typography>
        )}
        <LabelWithInput
          label="Email Address"
          id="email"
          value={registerEmail}
          placeholder="Email Address"
          type="email"
          onChange={handleRegisterEmail}
        />
        {emailError && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: 1 }}
          >
            {emailError}
          </Typography>
        )}
        <LabelWithInput
          label="Password"
          id="Password"
          value={registerPassword}
          placeholder="Password"
          onChange={handleUserPassword}
          type="password"
        />
        {passwordError && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: 1 }}
          >
            {passwordError}
          </Typography>
        )}
        <LabelWithInput
          label="Re-enter Password"
          id="confirmPassword"
          value={confirmRegisterPassword}
          placeholder="Password"
          onChange={handleConfirmUserPassword}
          type="password"
        />
        {confirmPasswordError && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: 1 }}
          >
            {confirmPasswordError}
          </Typography>
        )}
        <Box sx={{ display: "none", alignItems: "center", mt: 2 }}>
          <input type="file" accept="image/*" ref={fileInputRef} hidden />
          <ButtonPrimary
            type="button"
            onClick={() => fileInputRef.current.click()}
            sx={{
              background: "#1c9ac0",
              color: "white",
              height: "40px",
              px: 3,
              borderRadius: 1,
              letterSpacing: "1px",
              fontFamily: "Barlow",
              fontSize: "14px",
              fontWeight: "600",
              "&:hover": {
                background: "#1c9ac0",
                color: "white",
                opacity: 0.9,
              },
            }}
          >
            Upload Image
          </ButtonPrimary>
          {selectedImage && (
            <Typography sx={{ ml: 2 }}>{selectedImage.name}</Typography>
          )}
        </Box>
        {errorMessage && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: 2 }}
          >
            {errorMessage}
          </Typography>
        )}
        <ButtonPrimary
          type="submit"
          sx={{
            background: "#1c9ac0",
            color: "white",
            height: "64px",
            px: 6,
            marginTop: "19px",
            borderRadius: 1,
            letterSpacing: "3px",
            fontFamily: "Barlow",
            fontSize: "14px",
            fontWeight: "600",
            "&:hover": {
              background: "#1c9ac0",
              color: "white",
              opacity: 0.9,
            },
            ml: "0px",
            position: "relative",
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (
            "REGISTER NOW"
          )}
        </ButtonPrimary>
        <Box
          sx={{
            color: "#1c9ac0",
            fontSize: "16px",
            lineHeight: "29px",
            fontWeight: 400,
            fontFamily: "sans-serif",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "18px",
          }}
        >
          <Box>
            <Box
              sx={{ marginTop: "10px", display: { xs: "none", md: "block" } }}
            >
              <span style={{ color: "rgb(121, 127, 137)" }}>
                Already have an account?
              </span>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal("signin")}
              >
                {" "}
                Login
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterForm;
