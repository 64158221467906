import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthContext } from "../src/context/auth-context";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Listings from "./pages/Listings.jsx";
import Contact from "./pages/Contact.jsx";
import Blog from "./pages/Blog.jsx";
import ListingDetails from "./pages/ListingDetails.jsx";
import BlogDetail from "./pages/BlogDetail";
import MoveUpBtn from "./components/UI/MoveUpBtn.jsx";
import NotFound from "./pages/NotFound";
import DashBoard from "./pages/DashBoard";
import ProtectedRoute from "./components/ProtectedRoute";
import CreateListing from "./components/Dashboard/CreateListing.jsx";
import CreateUser from "./components/Dashboard/CreateUser.jsx";
import DashboardListing from "./components/Dashboard/DashboardListing.jsx";
import { DashboardProvider } from "./context/DashboardContext.js";
import { PendingCounterProvider } from "./context/pending-counter.js";
import LoginForm from "./components/UI/LoginForm.jsx";
import RegisterForm from "./components/UI/RegisterForm.jsx";
import { useAuth } from "./context/auth-hook.js";
import ListingComapanyDetails from "./pages/ListingComapanyDetails.jsx";

const MainLayout = () => (
  <>
    <Navbar />
    <ScrollToTop />
    <Outlet />
    <Footer />
    <MoveUpBtn />
  </>
);

const NotFoundLayout = () => (
  <>
    <ScrollToTop />
    <NotFound />
    <MoveUpBtn />
  </>
);

const App = () => {
  const { token, login, logout, userId, userRole, userEmail } = useAuth();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/login",
          element: <LoginForm />,
        },
        {
          path: "/signup",
          element: <RegisterForm />,
        },
        {
          path: "/about-us",
          element: <About />,
        },
        {
          path: "/listings",
          element: <Listings />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/business/:slug",
          element: <ListingComapanyDetails />,
        },
        {
          path: "/listings/:pid",
          element: <ListingDetails />,
        },
        {
          path: "/blogdetail/:id",
          element: <BlogDetail />,
        },
        {
          path: "/dashboard",
          element: (
            <ProtectedRoute>
              <PendingCounterProvider>
                <DashboardProvider>
                  <DashBoard />
                </DashboardProvider>
              </PendingCounterProvider>
            </ProtectedRoute>
          ),
          children: [
            {
              path: "createuser",
              element: <CreateUser />,
            },
            {
              path: "createlisting",
              element: <CreateListing />,
            },
            {
              path: "listings",
              element: <DashboardListing />,
            },
            {
              path: "*",
              element: <NotFound />,
            },
          ],
        },
        {
          path: "*",
          element: <NotFoundLayout />,
        },
      ],
    },
  ]);

  return (
    <DashboardProvider>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          userRole: userRole,
          userEmail: userEmail,
          login: login,
          logout: logout,
        }}
      >
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </DashboardProvider>
  );
};

export default App;
