import { Grid } from "@mui/material";
import React from "react";
import aboutBanner from "../assets/images/aboutBanner.avif";
import PageHeader from "../components/UI/PageHeader";
import Journey from "../components/AboutUs/Journey";
import Discover from "../components/AboutUs/Discover";
import AboutUsCard from "../components/AboutUs/AboutUsCard";
import Team from "../components/AboutUs/Team";

const About = () => {
  return (
    <Grid>
      <PageHeader image={aboutBanner} page="About Us" />
      <Journey />
      <Discover />
      <AboutUsCard />
      <Team />
    </Grid>
  );
};

export default About;
