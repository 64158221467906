import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import Blogdata from "./BlogData";
import SellIcon from "@mui/icons-material/Sell";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Form from "../UI/Form";

const BlogDetailCard = ({ id }) => {
  const item = Blogdata.find((blog) => blog.id === id);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFeedbackSubmit = async (formData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ name: "", email: "", comment: "" });
  };

  if (!item) {
    return <Typography variant="h5">Blog not found</Typography>;
  }

  const renderCategory = (item) => {
    return item.category ? (
      <Typography sx={{ color: "grey" }}>{item.category}</Typography>
    ) : (
      <Typography sx={{ color: "grey" }}>Uncategorized</Typography>
    );
  };

  return (
    <Grid
      container
      item
      sx={{
        background: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        gap: { xs: 6, md: 3 },
      }}
      rowSpacing={6}
      pt={{ xs: 5, md: 5 }}
      pb={{ xs: 8, md: 12 }}
      px={{ xs: 0, md: 1, lg: 6 }}
    >
      <Grid item xs={10} sx={{ mb: 4 }}>
        <Grid
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            transition: "transform 0.3s, box-shadow 0.3s",
          }}
        >
          <img height="450px" width="100%" src={item.path} alt={item.label} />
          <Typography
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              py: { md: "10px", sm: "10px", xs: "5px" },
              pr: { md: "45px", sm: "10px", xs: "5px" },
              pl: { md: "25px", sm: "10px", xs: "5px" },
              borderRadius: "8px",
              bgcolor: "#1c9ac0",
              color: "#ffffff",
              width: { md: "45px", sm: "35px", xs: "25px" },
              fontWeight: { md: "600", sm: "400", xs: "400" },
              textAlign: "center",
            }}
          >
            {item.datePublished}
          </Typography>
        </Grid>

        <Box sx={{ display: "flex", mb: 2, mt: 2 }}>
          <SellIcon sx={{ color: "#1c9ac0" }} />
          <Typography sx={{ color: "grey", ml: 1 }}>
            {renderCategory(item)}
          </Typography>
        </Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: "40px",
            fontWeight: "600",
            lineHeight: "auto",
            letterSpacing: "0.2px",
            textAlign: "left",
            mb: "20px",
            height: "auto",
            mr: "10px",
            fontFamily: "Barlow,sans-serif",
          }}
        >
          {item.label}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "grey",
            textAlign: "left",
            lineHeight: "1.8rem",
            height: "auto",
            mb: "20px",
            fontSize: "20px",
          }}
        >
          {item.description}
        </Typography>

        {item.headings.map((headingsItem, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                letterSpacing: "0.5px",
                textAlign: "left",
                mb: "10px",
                lineHeight: "1.8rem",
              }}
            >
              {headingsItem.h1}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "grey",
                textAlign: "left",
                lineHeight: "1.8rem",
                fontSize: "16px",
                mb: "20px",
              }}
            >
              {headingsItem.p}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                letterSpacing: "0.5px",
                textAlign: "left",
                mb: "10px",
                lineHeight: "1.8rem",
              }}
            >
              {headingsItem.h2}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "grey",
                textAlign: "left",
                lineHeight: "1.8rem",
                fontSize: "16px",
                mb: "20px",
              }}
            >
              {headingsItem.p2}
            </Typography>
            <List sx={{ mb: 4 }}>
              {headingsItem.lists?.map((listsItem, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                    <FiberManualRecordIcon
                      sx={{ fontSize: "10px", mt: "10px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "600",
                          color: "grey",
                          fontSize: "16px",
                        }}
                      >
                        {listsItem.listH}
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "400",
                            color: "grey",
                            fontSize: "16px",
                          }}
                        >
                          {` ${listsItem.listP}`}
                        </Typography>
                      </Typography>
                    }
                    primaryTypographyProps={{
                      sx: {
                        textAlign: "left",
                        lineHeight: "1.8rem",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
        <Typography
          variant="h3"
          sx={{
            fontSize: "40px",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textAlign: "left",
            mb: "20px",
            height: "60px",
            mr: "10px",
          }}
        >
          FAQs
        </Typography>

        {item.faq.map((faqItem, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                letterSpacing: "0.5px",
                textAlign: "left",
                mb: "10px",
                lineHeight: "1.8rem",
              }}
            >
              {faqItem.q}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "grey",
                textAlign: "left",
                lineHeight: "1.8rem",
                fontSize: "16px",
              }}
            >
              {faqItem.a}
            </Typography>
          </Box>
        ))}

        <Typography
          variant="h3"
          sx={{
            fontSize: "40px",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textAlign: "left",
            mb: "20px",
            height: "60px",
            mr: "10px",
          }}
        >
          Conclusion
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "grey",
            textAlign: "left",
            lineHeight: "1.8rem",
            height: "auto",
            fontSize: "18px",
            mb: "40px",
          }}
        >
          {item.conclusion}
        </Typography>
        <Grid container spacing={1} sx={{ my: 2 }}>
          {item.tags.map((tag, index) => (
            <Grid item key={index}>
              <Chip
                label={tag}
                sx={{
                  "&:hover": {
                    bgcolor: "#1c9ac0",
                    cursor: "default",
                    color: "#ffffff",
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid sx={{ my: "20px" }}>
         
          <Divider />
        </Grid>
        <Grid sx={{ textAlign: "left", ml: {xs: -2, sm:0} }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "26px",
              fontWeight: "600",
              letterSpacing: "0.5px",
              textAlign: "left",
              height: "60px",
              ml: "8px",
            }}
          >
            Add a Comment
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "grey",
              textAlign: "left",
              lineHeight: "1.8rem",
              height: "auto",
              fontSize: "16px",
              mb: "20px",
              ml: "8px",
            }}
          >
            Your email address will not be published.
          </Typography>
          <Form
          fields={[
            {
              type: "text",
              placeholder: "Enter your name",
              name: "user_name",
              required: true,
            },
            {
              type: "email",
              placeholder: "Enter your email",
              name: "user_email",
              required: true,
            },
          ]}
          buttonText="Submit"
          textAreaPlaceholder="Write Your Comment"
          onSubmit={handleFeedbackSubmit}
        />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogDetailCard;
