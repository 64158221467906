import { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { AuthContext } from "../../context/auth-context";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import { DashboardContext } from "../../context/DashboardContext";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultImg from "../../assets/images/placeholder.png";
import useFormattedPhoneNo from "../../hooks/useFormattedPhoneNo";

const PropertyItemCard = ({ item, handleDeleteItem }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [imgError, setImgError] = useState(false);
  const { selectedSection, setSelectedSection } = useContext(DashboardContext);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const truncateDesc = (desc, maxLength) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = desc;
    return tempElement.textContent.length > maxLength
      ? tempElement.textContent.substring(0, maxLength) + "..."
      : tempElement.textContent;
  };

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "..";
    }
    return title;
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = (id) => {
    handleDeleteItem(id);
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleImageError = () => {
    setImgError(true);
  };

  const handleEditClick = (id) => {
    navigate(`/dashboard/createlisting/update?id=${id}`);
    setSelectedSection(`/dashboard/createlisting/update?id=${id}`);
  };

  const shouldHide = item.website === "https://www.example.com/";

  const formatPhoneNumber = useFormattedPhoneNo(item.phone);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3.6}
      sx={{
        padding: { xs: "5px", sm: "9px", md: "0px", position: "relative" },
      }}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <Link
          to={`/listings/${item.id}?type=listing`}
          style={{ textDecoration: "none" }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 0,
              cursor: "pointer",
              position: "relative",
              transition: "transform 0.3s",
              borderRadius: "6px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              "&:hover": {
                transform: "scale(1.03)",
                "& Button": {
                  color: "white",
                  opacity: 0.9,
                },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "180px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                opacity: 1,
                transition: "opacity 0.3s",
              }}
            ></Box>
            <CardMedia
              component="img"
              height="180"
              image={
                !imgError && item.image
                  ? `${backendUrl}/${item.businessBanner}`
                  : DefaultImg
              }
              alt={item.title}
              onError={handleImageError}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
                position: "absolute",
                top: "10px",
                right: "20px",
              }}
            >
              {auth.isLoggedIn && auth.userRole === 1 ? (
                <>
                  <Box
                    style={{
                      borderRadius: "50%",
                      border: "1px solid #fff",
                      padding: "4px",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      backgroundColor: "#1c9ac0",
                    }}
                    onClick={() => handleEditClick(item.id)}
                  >
                    <EditIcon
                      style={{
                        color: "#fff",
                        display: "flex",
                        margin: "auto",
                        position: "relative",
                        fontSize: "24px",
                        top: "3px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      borderRadius: "50%",
                      border: "1px solid #fff",
                      padding: "4px",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      backgroundColor: "#1c9ac0",
                    }}
                    onClick={() => handleDeleteClick(item.id)}
                  >
                    <DeleteIcon
                      style={{
                        color: "#fff",
                        display: "flex",
                        margin: "auto",
                        position: "relative",
                        fontSize: "24px",
                        top: "3px",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>

            <CardContent
              sx={{
                flexGrow: 1,
                pb: 1,
                width: "100%",
                position: "relative",
                minHeight: "260px",
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex" },
                  justifyContent: { xs: "space-between" },
                }}
              >
                <img
                  style={{
                    width: "63px",
                    height: "63px",
                    borderRadius: "50%",
                    border: "4px solid white",
                    position: "absolute",
                    top: "-34px",
                    left: "30px",
                  }}
                  src={
                    !imgError && item.businessLogo
                      ? `${backendUrl}/${item.businessLogo}`
                      : DefaultImg
                  }
                  alt={item.title}
                  onError={handleImageError}
                />
                <Box
                  className="rating"
                  sx={{
                    display: "flex",
                    gap: 1,
                    position: "absolute",
                    right: "20px",
                    top: "-36px",
                    px: "10px",
                  }}
                >
                  <Box sx={{ color: "#ff9e00" }}>
                    <StarIcon sx={{ fontSize: "19px" }} />
                    <StarIcon sx={{ fontSize: "19px" }} />
                    <StarIcon sx={{ fontSize: "19px" }} />
                    <StarIcon sx={{ fontSize: "19px" }} />
                    <StarIcon sx={{ fontSize: "19px" }} />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      color: "white",
                    }}
                  >
                    5 / <span style={{ color: "#ffffffbd" }}> 5</span>
                  </Typography>
                </Box>
              </Box>
              <Link
                to={`/listings/${item.id}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "black",
                    fontWeight: "600",
                    letterSpacing: "1px",
                    lineHeight: "26px",
                    textAlign: "left",
                    fontFamily: "poppins",
                    fontSize: "16px",
                    marginBottom: "5px",
                    marginTop: "30px",
                    height: "72px",
                    "&:hover": { color: "#1c9ac0" },
                    px: "20px",
                  }}
                >
                  {item.title}
                </Typography>
              </Link>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontWeight: "500",
                  letterSpacing: "1px",
                  lineHeight: "23px",
                  textAlign: "left",
                  marginTop: "10px",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  marginBottom: "10px",
                  color: "#4a4a4a",
                  px: "20px",
                  height: "45px",
                }}
              >
                {truncateDesc(item.desc, 50)}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  px: "16px",
                }}
              >
                <RoomIcon
                  style={{
                    fontSize: "20px",
                    position: "relative",
                    color: "#1c9ac0",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    width: "auto",
                    color: "#4a4a4a",
                    fontSize: "12px",
                    marginBottom: "5px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textAlign: "left",
                  }}
                >
                  {truncateDesc(item.address, 100)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  px: "16px",
                  mt: "0.5rem",
                }}
              >
                <CallIcon
                  style={{
                    fontSize: "20px",
                    position: "relative",
                    color: "#1c9ac0",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    width: "auto",
                    color: "#4a4a4a",
                    fontSize: "12px",
                    marginBottom: "5px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textAlign: "left",
                  }}
                >
                  <Link
                    onClick={handleLinkClick}
                    to={`tel:${item.phone}`}
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    {formatPhoneNumber}
                  </Link>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  px: "16px",
                  mt: "0.5rem",
                }}
              >
                <OpenInNewIcon
                  style={{
                    fontSize: "20px",
                    position: "relative",
                    color: "#1c9ac0",
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{
                    color: "#4a4a4a",
                    textAlign: "left",
                    fontSize: "12px",
                    marginBottom: "5px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                  }}
                >
                  {!shouldHide ? (
                    <Link
                      onClick={handleLinkClick}
                      to={item.website}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      {truncateDesc(item.website, 50)}
                    </Link>
                  ) : (
                    <Link
                      onClick={handleLinkClick}
                      to={shouldHide ? "#" : item.website}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      -
                    </Link>
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Link>
      </Box>

      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-desc"
      >
        <Box
          sx={{
            bgcolor: "#fff",
            width: "300px",
            p: 4,
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
        >
          <Typography
            variant="h6"
            id="delete-modal-title"
            sx={{ color: "black" }}
          >
            Are you sure you want to delete this item?
          </Typography>
          <Box mt={2} sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteConfirm(item.id)}
              sx={{ mr: 1.5 }}
            >
              Delete
            </Button>
            <Button variant="outlined" onClick={handleCancelDelete}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default PropertyItemCard;
