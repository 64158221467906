import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FooterHeading from "./FooterHeading";
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import CompanyContactData from "./Data/CompanyContactInfo";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import bgImage from "../assets/images/bg-footer-1.png";
import header from "../assets/images/header.png";
import ButtonPrimary from "./UI/ButtonPrimary";
import { makeStyles } from "@mui/styles";
import { SocialIcon } from "react-social-icons";
import { SnackbarProvider, useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  footerLink: {
    "&:hover": {
      color: "white",
    },
    color: "#abafb5",
    textDecoration: "none",
    fontWeight: 500,
  },
  footerLinkLi: {
    marginBottom: "21px",
    marginLeft: "15px",
    transition: "transform 0.3s",
    fontSize: "15px",
    fontWeight: "600",
    fontFamily: "'Roboto', sans-serif",
  },
  newsLetterInput: {
    width: "100%",
    padding: "24px 0px 24px 10px",
    textAlign: "center",
    fontSize: "15px",
    borderRadius: "5px",
    border: "none",
    marginBottom: "9px",
  },
}));

const { email, phone, OfficeLocation, tiktok, pinterest, facebook, instagram } =
  CompanyContactData[0];
const Footer = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formemail, setFormEmail] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();
  const location = useLocation();
  const footerSocialIcon = {
    padding: "2px",
  };
  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  if (
    location?.pathname === "/dashboard" ||
    /^\/business\//.test(location?.pathname) ||
    isDashboardRoute
  ) {
    return null;
  }

  const handleChange = (e) => {
    setFormEmail(e.target.value);
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formemail) {
      setError("Email is required");
      return;
    }

    if (!validateEmail(formemail)) {
      setError("Please enter a valid email address");
      return;
    }

    enqueueSnackbar("Subscribed successfully!", {
      variant: "success",
      autoHideDuration: 3000,
      action: (key) => (
        <Button color="inherit" size="small" onClick={() => closeSnackbar(key)}>
          Close
        </Button>
      ),
    });

    setFormEmail("");
    setError("");
  };




  return (
    <>
      <div style={{ background: "#161c26" }}>
        <Box
          component="footer"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: "top left",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          py={{ xs: 3, md: 8 }}
          px={{ xs: 2, sm: 4, md: 0 }}
          color="grey.300"
        >
          <Container maxWidth="lg">
            <Grid container spacing={8}>
              <Grid item xs={12} md={3.4}>
                <Box mb={2} mt={-2}>
                  <Link href="/" passHref>
                    <IconButton component="a" aria-label="Cruip">
                      <img src={header} alt="" style={{ width: "100px" }} />
                    </IconButton>
                  </Link>
                </Box>
                <Typography
                  variant="body2"
                  color="grey.400"
                  style={{
                    fontSize: "15px",
                    lineHeight: "30px",
                    paddingBottom: "15px",
                    paddingTop: "8px",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Get Started to Unearth the finest local business listings
                  available in your city. The journey toward incredible
                  experiences begins now.
                </Typography>
                
              </Grid>
              <Grid item xs={12} sm={4} md={2.4}>
                <FooterHeading Text="Quick Links" linebg="#1c9ac0" />
                <div style={{ paddingTop: "18px" }}>
                  <ul style={{ padding: "0px" }}>
                    <li
                      className={classes.footerLinkLi}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateX(5px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateX(0px)")
                      }
                    >
                      <Link
                        to="/"
                        className={classes.footerLink}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      className={classes.footerLinkLi}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateX(5px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateX(0px)")
                      }
                    >
                      <Link
                        to="/about-us"
                        className={classes.footerLink}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        About us
                      </Link>
                    </li>
                    <li
                      className={classes.footerLinkLi}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateX(5px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateX(0px)")
                      }
                    >
                      <Link
                        to="/listings"
                        className={classes.footerLink}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Listing
                      </Link>
                    </li>
                    <li
                      className={classes.footerLinkLi}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateX(5px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateX(0px)")
                      }
                    >
                      <Link
                        to="/blog"
                        className={classes.footerLink}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Blog
                      </Link>
                    </li>
                    <li
                      className={classes.footerLinkLi}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "translateX(5px)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "translateX(0px)")
                      }
                    >
                      <Link
                        to="/contact"
                        className={classes.footerLink}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FooterHeading Text="Newsletter" linebg="#1c9ac0"/>
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 4, height: "44px" }}
                  onSubmit={handleSubmit}
                >
                  <input
                    style={{
                      height: "10px",
                      border: "1px solid #1c9ac0",
                      borderRadius: "5px",
                      textAlign: "left",
                      fontFamily: "'Roboto', sans-serif",
                    }}
                    type="email"
                    required="true"
                    placeholder="Enter E-mail Address"
                    className={classes.newsLetterInput}
                    value={formemail}
                    onChange={handleChange}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>{error}</div>
                  <ButtonPrimary
                    type="submit"
                    sx={{
                      background: "#1c9ac0",
                      color: "white",
                      height: "50px",
                      width: "50%",
                      px: 6,
                      borderRadius: "5px",
                      mt: 1,
                      "&:hover": {
                        background: "#1c9ac0",
                        color: "white",
                        opacity: 0.9,
                      },
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    SUBSCRIBE
                  </ButtonPrimary>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={3.2}
                sx={{ mt: { xs: "30px", sm: "0px", md: "0px" } }}
              >
                <FooterHeading Text="Contact" linebg="#1c9ac0" />
                <div style={{ paddingTop: "15px" }}>
                  <Box sx={{ display: "flex" }}>
                    <LocationOnIcon
                      sx={{
                        color: "#abafb5",

                        marginBottom: 0,
                        marginTop: "16px",
                        mr: 1,
                      }}
                    />
                    <p
                      style={{
                        color: "#abafb5",
                        marginBottom: "0px",
                        fontSize: "15px",
                        lineHeight: "24px",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      {OfficeLocation}
                    </p>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <MailIcon
                      sx={{
                        color: "#abafb5",
                        marginBottom: "0px",
                        marginTop: "28px",
                        mr: 1,
                      }}
                    />
                    <a
                      href="mailto:info@prismx.net"
                      style={{
                        display: "block",
                        textDecoration: "none",
                        color: "#abafb5",
                        marginTop: "30px",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      {email}
                    </a>
                  </Box>
                  <a
                    href={`tel:${phone}`}
                    style={{ textDecoration: "none", color: " unset" }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <CallIcon
                        sx={{
                          color: "#abafb5",
                          marginBottom: "0px",
                          marginTop: "28px",
                          mr: 1,
                          cursor: "pointer",
                        }}
                      />
                      <p
                        style={{
                          color: "#abafb5",
                          marginBottom: "0px",
                          marginTop: "30px",
                          fontFamily: "'Roboto', sans-serif",
                          cursor: "pointer",
                        }}
                      >
                        {phone}
                      </p>
                    </Box>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
      <Box sx={{ color: "grey.300" }}>
        <Container maxWidth={false} sx={{ px: { xs: 0 } }}>
          <Grid
            container
            py={1}
            sx={{
              background: "black",
              color: "white",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                fontSize: "12px",
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              <small>Copyright 2023 Prism X. All Rights Reserved.</small>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <style>
        {`
        @keyframes bottomtotop {
          49% {
            transform: translateY(-100%);
          }
          50% {
            opacity: 0;
            transform: translateY(100%);
          }
          51% {
            opacity: 1;
          }
        }

        .tiktokIcon:hover {
          animation: bottomtotop 0.6s linear forwards;
        }
        .tiktokIcon{
          color: white ;
        }
        .tiktokIcon svg {
          fill: white !important;
        }
        `}
      </style>
    </>
  );
};

const EnhancedNewsletterForm = () => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    autoHideDuration={3000}
  >
    <Footer />
  </SnackbarProvider>
);

export default EnhancedNewsletterForm;
