import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const BlogCard = ({ data, itemsPerPage }) => {
  return (
    <Grid
      container
      item
      sx={{
        background: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "left",
        gap: { xs: 1, sm: 4, md: 3 },
      }}
      rowSpacing={6}
      pt={{ xs: 4, md: 5 }}
      pb={{ xs: 8, md: 12 }}
      px={{ xs: 2, sm: 6 }}
    >
      {data.map((item, index) => (
        <Grid item xs={12} sm={5.7} md={3.8} key={index}>
          <Link
            to={`/blogdetail/${item.id}`}
            style={{ textDecoration: "none" }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
                pb: 4,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                  "& Button": {
                    background: "#1c9ac0",
                    color: "white",
                    opacity: 1,
                  },
                  "& h5": {
                    color: (theme) => theme.palette.primary.hover,
                  },
                },
              }}
            >
              <CardMedia
                component="img"
                height="240"
                image={item.path}
                alt={item.label}
                sx={{ cursor: "pointer" }}
              />
              <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    letterSpacing: "0.2px",
                    lineHeight: "1.9rem",
                    cursor: "pointer",
                    "&:hover": { color: "#1c9ac0" },
                    textAlign: "left",
                    mb: "20px",
                    height: "56px",
                    textOverflow: "ellipsis",
                    wordBreak: "break-all",
                    WebkitHyphens: "auto",
                    mr: "25px",
                    color: "inherit",
                  }}
                >
                  {`${item.label.substring(0, 46)}${
                    item.label.length > 46 ? "..." : ""
                  }`}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "grey",
                    textAlign: "left",
                    lineHeight: "auto",
                    maxHeight: "auto",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 6,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {`${item.description.substring(0, 156)}${
                    item.description.length > 156 ? "..." : ""
                  }`}
                </Typography>
              </CardContent>

              <Button
                sx={{
                  background: "black",
                  color: "#ffffff",
                  textTransform: "none",
                  height: "42px",
                  width: "auto",
                  px: 6,
                  borderRadius: 20,
                }}
              >
                View Details
              </Button>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogCard;
