import React from "react";
import Box from "@mui/material/Box";
import { Typography, Container } from "@mui/material";

const SectionIntro = ({ title, subtitle, description, subtitleColor }) => {
  return (
    <Container maxWidth="md">
      <Box component="section" pt="1rem" pb="2.5rem">
        <Typography
          variant="body2"
          style={{
            fontSize: "14px",
            lineHeight: "32px",
            fontFamily: "Barlow",
            color: "#1c9ac0",
            textAlign: "center",
            fontWeight: "600",
            letterSpacing: "3px",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h2"
          style={{
            fontSize: "43px",
            lineHeight: "32px",
            fontFamily: "Barlow",
            color: subtitleColor ? subtitleColor : "#161c26",
            textAlign: "center",
            fontWeight: "800",
            marginTop: "10px",
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "16px",
            lineHeight: "27px",
            fontFamily: "sans-serif",
            color: "#797f89",
            textAlign: "center",
            fontWeight: "400",
            marginTop: "18px",
            padding: "0px 20px",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Container>
  );
};

export default SectionIntro;
