import { Box, Grid, Pagination } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import BlogCard from "../components/Blog/BlogCard";
import PageHeader from "../components/UI/PageHeader";
import BlogSearchBar from "../components/Blog/BlogSearchBar";
import Blogdata from "../components/Blog/BlogData";
import BlogSideBar from "../components/Blog/BlogSideBar";
import NotFound from "./NotFound";
import BlogBanerimage from "../assets/images/BlogBanerimage.jpg";

const Blog = () => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(Blogdata);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const sidebarRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [, setLastScrollY] = useState(0);
  const [sidebarBottom, setSidebarBottom] = useState(0);
  const [sidebarTopWhenSticky, setSidebarTopWhenSticky] = useState(0);

  useEffect(() => {
    if (location.state && location.state.selectedCategory) {
      const selectedCategory = location.state.selectedCategory;
      const filtered = Blogdata.filter((item) =>
        selectedCategory === "Uncategorized"
          ? !item.category
          : item.category === selectedCategory
      );
      setFilteredData(filtered);
    } else if (location.state && location.state.selectedTag) {
      const selectedTag = location.state.selectedTag;
      const filtered = Blogdata.filter((item) =>
        item.tags.includes(selectedTag)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(Blogdata);
    }
  }, [location.state]);

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    const filtered = Blogdata.filter((item) => {
      const lowerCaseQuery = query.toLowerCase();
      return (
        item.label.toLowerCase().includes(lowerCaseQuery) ||
        item.description.toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    if (sidebarRef.current) {
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      const sidebarEndBottom = sidebarRect.bottom + currentScrollY;

      if (sidebarBottom === 0) {
        setSidebarBottom(sidebarEndBottom);
      }

      if (currentScrollY + windowHeight >= sidebarBottom) {
        if (!isSticky) {
          setSidebarTopWhenSticky(sidebarRect.top);
          setIsSticky(true);
        }
      } else {
        if (isSticky) {
          setIsSticky(false);
        }
      }
    }

    setLastScrollY(currentScrollY);
  }, [isSticky, sidebarBottom]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Grid container>
      <PageHeader image={BlogBanerimage} page="LATEST POSTS" />
      <Grid
        container
        sx={{ display: { lg: "flex", sm: "block", xs: "block" } }}
      >
        <Box sx={{ flex: 1 }}>
          {filteredData.length > 0 ? (
            <>
              <Box
                ref={sidebarRef}
                sx={{
                  flex: 1,
                  display: {
                    xs: "block",
                    md: "none",
                  },
                  width: "100%",
                }}
              >
                <BlogSearchBar
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                />
                <BlogSideBar />
              </Box>
              <Box>
                <BlogCard data={currentItems} itemsPerPage={itemsPerPage} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "60px",
                }}
              >
                <Pagination
                  count={Math.ceil(filteredData.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Box>
            </>
          ) : (
            <NotFound />
          )}
        </Box>
        <Box
          ref={sidebarRef}
          sx={{
            flex: 0.3,
            display: {
              xs: "none",
              md: "block",
            },
            width: {
              md: "40%",
              lg: "30%",
            },
            position: isSticky ? "sticky" : "relative",
            top: isSticky ? sidebarTopWhenSticky : "auto",
            alignSelf: "flex-start",
          }}
        >
          <BlogSearchBar
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <BlogSideBar />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Blog;
