import React from "react";
import { Typography } from "@mui/material";

const FooterHeading = ({ Text , linebg}) => {
  return (
    <div>
      <Typography
        variant="body2"
        sx={{
          position: "relative",
          fontSize: "20px",
          fontWeight: "700",
          color: "white",
          pb: "20px",
          fontFamily: "poppins",
          "&::after": {
            content: '""',
            height: "4px",
            width: "30px",
            backgroundColor: linebg,
            borderRadius: "30px",
            position: "absolute",
            bottom: 0,
            left: 0,
          },
        }}
      >
        {Text}
      </Typography>
    </div>
  );
};

export default FooterHeading;
