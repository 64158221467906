import React from "react";
import { Box, Grid } from "@mui/material";
import { data } from "../../components/Listing/data";
import TitleHeading from "../UI/TitleHeading";
import PropertyItemCard from "../Listing/PropertyCardItem";

const RealtedBusinessCard = ({ listingforCategory, listing }) => {
  
  const matchedCategories = data.filter((item) => {
    if (!Array.isArray(listingforCategory.category)) {
      return (
        item.category === listingforCategory.category &&
        item.slug !== listing.slug
      );
    } else {
      return (
        listingforCategory.category.some((category) =>
          item.category.includes(category)
        ) && item.slug !== listing.slug
      );
    }
  });

  if (matchedCategories.length === 0) {
    return <Box component="section"></Box>;
  }

  return (
    <Box component="section">
      <TitleHeading text="Related Businesses" pt={4} mb={3} />

      <Grid container spacing={2}>
        {matchedCategories.slice(0, 2).map((item, index) => (
          <PropertyItemCard key={index} item={item} />
        ))}
      </Grid>
    </Box>
  );
};

export default RealtedBusinessCard;
