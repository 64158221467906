import React, { createContext, useEffect, useState } from "react";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const [selectedSection, setSelectedSection] = useState(
    "/dashboard/createuser"
  );
  const [user, setUser] = useState();

  useEffect(() => {
    if (!user) {
      const userName = localStorage.getItem("userName");
      setUser(userName);
    }
  }, [user]);

  return (
    <DashboardContext.Provider
      value={{ selectedSection, setSelectedSection, user, setUser }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };
