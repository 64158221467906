import React from "react";
import { Grid, Typography, Box, Pagination } from "@mui/material";
import DashboardCardItem from "./DashboardCardItem";

const CreateListing = ({
  handleDeleteItem,
  setItems,
  items,
  setCurrentPage,
  currentPage,
  totalPages,
  isAPIRunning,
}) => {
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Grid
        container
        item
        sx={{
          textAlign: "center",
          display: "flex",
          ml: { lg: "15px", md: "-35px", sm: "-55px", xs: "-45px" },
          mr: { sm: "45px", xs: "32px", md: "0px" },
          justifyContent: items.length === 0 ? "center" : "flex-start",
          gap: { xs: "20px", lg: "10px", sm: "30px", md: "10px" },
        }}
        pt={{ xs: 4, md: 0 }}
        pb={{ xs: 8, md: 12 }}
        pr={{ xs: 0, md: "20px", sm: "0px" }}
        pl={{ xs: 0, sm: "0px" }}
      >
        {items.length === 0 ? (
          <Typography
            variant="h6"
            sx={{ mt: -7, color: "black", fontSize: "25px" }}
          >
            No Listings Found
          </Typography>
        ) : (
          items.map((item, index) => (
            <DashboardCardItem
              key={index}
              item={item}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              items={items}
              setItems={setItems}
              handleDeleteItem={handleDeleteItem}
              totalPages={totalPages}
              isAPIRunning={isAPIRunning}
            />
          ))
        )}
      </Grid>
      {totalPages >= 1 && (
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            marginBottom: "60px !important",
            top: "-30px",
            position: "relative",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
};

export default CreateListing;
