import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LabelWithInput = ({
  label,
  id,
  name,
  value,
  onChange,
  placeholder,
  type,
  disabled = false,
  options,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div style={{ marginTop: "23px" }}>
      <label
        htmlFor={id}
        style={{
          color: "#11161f",
          fontWeight: 600,
          display: "block",
          fontFamily: "sans-serif",
          fontSize: "16px",
        }}
      >
        {label}
      </label>
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        {type === "select" ? (
          <select
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={{
              backgroundColor: "#fafbfc",
              border: "1px solid #e9e9ee",
              padding: "0 20px",
              maxWidth: "100%",
              borderRadius: "5px",
              lineHeight: "50px",
              color: "#797f89",
              width: "100%",
              marginTop: "12px",
              outline: "none",
              fontSize: "16px",
              cursor: disabled ? "not-allowed" : "pointer",
              height:"55px"
            }}
          >
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            type={showPassword ? "text" : type}
            disabled={disabled}
            style={{
              backgroundColor: "#fafbfc",
              border: "1px solid #e9e9ee",
              padding: "0 20px",
              maxWidth: "100%",
              borderRadius: "5px",
              lineHeight: "50px",
              color: "#797f89",
              width: "100%",
              marginTop: "12px",
              outline: "none",
              fontSize: "16px",
              paddingRight: type === "password" ? "40px" : "20px", 
              cursor: disabled ? "not-allowed" : "text",
            }}
            placeholder={placeholder}
          />
        )}
        {type === "password" && (
          <IconButton
            onClick={handleTogglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "43%",
              transform: "translateY(-50%)",
              marginTop: "12px",
              zIndex: 10,
            }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default LabelWithInput;