import React, { useState, useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TuneIcon from "@mui/icons-material/Tune";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ButtonPrimary from "../UI/ButtonPrimary";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { AuthContext } from "../../context/auth-context";

function DashboardListngSearch({
  setSearchKeyword,
  searchKeyword,
  data,
  category,
  setItems,
  setCategory,
  region,
  setRegion,
  city,
  setCity,
  priceRange,
  setPriceRange,
  setArea,
  area,
  setTotalPages,
}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const auth = useContext(AuthContext);
  const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [priceRangeOptions, setPriceRangeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [areaOptions, setAreaOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [service, setService] = useState("");
  const [accountingAndTaxService, setAccountingAndTaxService] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false); // New state

  useEffect(() => {
    const categories = [...new Set(data.map((item) => item.category))];
    setCategoryOptions(
      categories.map((category) => ({
        value: category,
        label: category,
      }))
    );

    const priceRanges = [...new Set(data.map((item) => item.priceRange))];
    setPriceRangeOptions(
      priceRanges.map((range) => ({
        value: range,
        label: `${range}`,
      }))
    );

    const areas = [...new Set(data.flatMap((item) => item.area || []))];
    setAreaOptions(
      areas.map((area) => ({
        value: area,
        label: area,
      }))
    );

    const services = [
      ...new Set(data.flatMap((item) => item.accountingAndTaxService || [])),
    ];
    setServiceOptions(
      services.map((service) => ({
        value: service,
        label: service,
      }))
    );

    if (!categories.includes(category)) {
      setCategory("");
    }
    if (!priceRanges.includes(priceRange)) {
      setPriceRange("");
    }
    if (!areas.includes(area)) {
      setArea("");
    }
    if (!accountingAndTaxService.includes(accountingAndTaxService)) {
      setAccountingAndTaxService("");
    }

    const regions = [...new Set(data.map((item) => item.region))];
    setRegionOptions(
      regions.map((region) => ({
        value: region,
        label: region,
      }))
    );

    if (!regions.includes(region)) {
      setRegion("");
    }

    if (region) {
      const filteredCities = data
        .filter((item) => item.region === region)
        .flatMap((item) => item.city || []);
      const uniqueCities = [...new Set(filteredCities)];
      setCityOptions(
        uniqueCities.map((city) => ({
          value: city,
          label: city,
        }))
      );
    }
  }, [data, category, priceRange, area, accountingAndTaxService, region]);

  const toggleAdvancedSearch = () => {
    setIsAdvancedSearchVisible((prev) => !prev);
  };

  const handleInputChange = (event) => {
    setSearchKeyword(event.target.value);
    checkIfFilterApplied(
      event.target.value,
      category,
      priceRange,
      area,
      region,
      city,
      accountingAndTaxService
    );
    if (event.target.value === "") {
      handleButtonClick();
    }
  };

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      const searchParams = {};
      if (category) searchParams.category = category;
      if (searchKeyword) searchParams.title = searchKeyword;
      if (priceRange) searchParams.priceRange = priceRange;
      if (city) searchParams.city = city;
      if (area) searchParams.area = area;
      if (region) searchParams.region = region;

      const response = await axios.get(`${backendUrl}/api/places`, {
        params: { search: JSON.stringify(searchParams) },
      });
      setItems(response.data.places);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = (event) => {
    setPriceRange(event.target.value);
    checkIfFilterApplied(
      searchKeyword,
      category,
      event.target.value,
      area,
      region,
      city,
      accountingAndTaxService
    );
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    checkIfFilterApplied(
      searchKeyword,
      event.target.value,
      priceRange,
      area,
      region,
      city,
      accountingAndTaxService
    );
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
    setCity("");
    checkIfFilterApplied(
      searchKeyword,
      category,
      priceRange,
      area,
      event.target.value,
      city,
      accountingAndTaxService
    );
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    checkIfFilterApplied(
      searchKeyword,
      category,
      priceRange,
      area,
      region,
      event.target.value,
      accountingAndTaxService
    );
  };

  const handleAreaChange = (event) => {
    setArea(event.target.value);
    checkIfFilterApplied(
      searchKeyword,
      category,
      priceRange,
      event.target.value,
      region,
      city,
      accountingAndTaxService
    );
  };

  const handleServiceChange = (event) => {
    setAccountingAndTaxService(event.target.value);
    checkIfFilterApplied(
      searchKeyword,
      category,
      priceRange,
      area,
      region,
      city,
      event.target.value
    );
  };

  const checkIfFilterApplied = (
    searchKeyword,
    category,
    priceRange,
    area,
    region,
    city,
    accountingAndTaxService
  ) => {
    setIsFilterApplied(
      searchKeyword ||
        category ||
        priceRange ||
        area ||
        region ||
        city ||
        accountingAndTaxService
    );
  };

  const clearFilter = () => {
    setSearchKeyword("");
    setCategory("");
    setPriceRange("");
    setArea("");
    setAccountingAndTaxService("");
    setRegion("");
    setCity("");
    setItems(data);
    setIsFilterApplied(false);
  };
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          padding: { sm: "0px", md: "0px 0px" },
          mt: "30px",
          ml: "auto",
          mr: "auto",
          width: { sm: "100%", xs: "100%", lg: "100%", md: "95%" },
        }}
      >
        <Box my={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} p={0}>
              <Paper
                elevation={3}
                style={{ padding: 16 }}
                sx={{
                  boxShadow:
                    " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} style={{ marginTop: "29px" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6.6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          gap: 1,
                          paddingLeft: { xs: "5px", lg: "25px" },
                        }}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          cursor: "pointer",
                          paddingTop: "20px",
                        }}
                        onClick={toggleAdvancedSearch}
                      >
                        <TuneIcon sx={{ pt: "5px" }} />{" "}
                        <Typography
                          sx={{
                            fontSize: { xs: "13px", lg: "0.9rem" },
                            paddingLeft: { xs: "0px", lg: "10px" },
                            pt: "5px",
                          }}
                        >
                          Advance Filter
                        </Typography>
                      </Grid>

                      {isFilterApplied && (
                        <Grid
                          item
                          xs={12}
                          sm={5.4}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: 1,
                            paddingLeft: { xs: "5px", lg: "25px" },
                          }}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            cursor: "pointer",
                            paddingTop: "20px",
                          }}
                          onClick={clearFilter}
                        >
                          <SearchOffIcon sx={{ pt: "5px" }} />{" "}
                          <Typography
                            sx={{
                              fontSize: { xs: "13px", lg: "0.9rem" },
                              paddingLeft: { xs: "0px", lg: "10px" },
                              pt: "5px",
                            }}
                          >
                            Clear Filter
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography
                      sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                    >
                      Category
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Category</InputLabel>
                      <Select
                        label="Category"
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        {categoryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <Typography sx={{ fontSize: "13px", pl: "2px" }}>
                      Keyword
                    </Typography>
                    <FormControl fullWidth>
                      <TextField
                        label="Search Keyword"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: "0px", marginTop: "10px" }}
                        value={searchKeyword}
                        onChange={handleInputChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleButtonClick();
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    sx={{
                      mt: "30px",
                      display: { xs: "flex", sm: "flex", md: "block" },
                    }}
                  >
                    <ButtonPrimary
                      onClick={handleButtonClick}
                      sx={{
                        background: "#1c9ac0",
                        color: "white",
                        height: "53px",
                        px: 1,
                        borderRadius: "5px",
                        textTransform: "capitalize",
                        fontSize: "12px",
                        float: "right",
                        width: "43px",
                        "&:hover": {
                          background: "#1c9ac0",
                          color: "white",
                          opacity: 0.9,
                        },
                        ml: { md: "auto", sm: "-15px", xs: "-10px" },
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        <SearchIcon />
                      )}
                    </ButtonPrimary>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: isAdvancedSearchVisible ? "block" : "none",
                  }}
                  id="advanceSearch"
                >
                  <Grid
                    container
                    spacing={2}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Grid item xs={12} sm={2.3}>
                      <Typography
                        sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                      >
                        Price Range
                      </Typography>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Price Range</InputLabel>
                        <Select
                          value={priceRange}
                          onChange={handlePriceChange}
                          label="Price Range"
                        >
                          {priceRangeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2.3}>
                      <Typography
                        sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                      >
                        Area
                      </Typography>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Area</InputLabel>
                        <Select
                          value={area}
                          onChange={handleAreaChange}
                          label="Area"
                        >
                          {["Canada", "United Kingdom", "United States"].map(
                            (option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2.3}>
                      <Typography
                        sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                      >
                        Services
                      </Typography>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Services</InputLabel>
                        <Select
                          value={accountingAndTaxService}
                          onChange={handleServiceChange}
                          label="Services"
                        >
                          {serviceOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2.3}>
                      <Typography
                        sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                      >
                        Region
                      </Typography>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Region</InputLabel>
                        <Select
                          value={region}
                          onChange={handleRegionChange}
                          label="Region"
                        >
                          {regionOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2.3}>
                      <Typography
                        sx={{ fontSize: "13px", pb: "10px", pl: "2px" }}
                      >
                        City
                      </Typography>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>City</InputLabel>
                        <Select
                          value={city}
                          onChange={handleCityChange}
                          label="City"
                          disabled={region === "" ? true : false}
                        >
                          {cityOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default DashboardListngSearch;
