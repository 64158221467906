import React, { useEffect, useState, useContext } from "react";
import ContactUs from "../components/CompanyDetails/ContactUs";
import { Link as ScrollLink, Element } from "react-scroll";
import CompanyTopbar from "../components/CompanyDetails/CompanyTopbar";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Drawer, List } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../components/CompanyDetails/Header";
import CompanyContactCrd from "../components/CompanyDetails/CompanyContactCrd";
import axios from "axios";
import CompanyAboutUs from "../components/CompanyDetails/CompanyAboutUs";
import CompanyCopyRight from "../components/CompanyDetails/CompanyCopyRight";
import CompanyServices from "../components/CompanyDetails/CompanyServices";
import CompanyLatestProject from "../components/CompanyDetails/CompanyLatestProject";
import MenuIcon from "@mui/icons-material/Menu";
import DummyListing from "../components/Data/DummyListing";
import CompanyAboutService from "../components/CompanyDetails/CompanyAboutService";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import CompanyBanText from "../components/CompanyDetails/CompanyBanText";
import CompanyCount from "../components/CompanyDetails/CompanyCount";
import useFormattedPhoneNo from "../hooks/useFormattedPhoneNo";
import NotFound from "../pages/NotFound";
import { AuthContext } from "../context/auth-context";

function Navbar({ logo, Number, isAPIRunning }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (window.innerWidth < 900) {
      setScrolled(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else if (window.innerWidth > 900) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box
      sx={{
        background: "#11161f",
        color: "white",
        paddingTop: "15px",
        paddingBottom: "15px",
        pl: "20px",
        display: "block",
        boxShadow: "none",
      }}
    >
      <List>
        {["Home", "About Us", "Services", "Latest Projects", "Contact Us"].map(
          (text) => (
            <ScrollLink
              key={text}
              to={text.toLowerCase().replace(" ", "-")}
              smooth={true}
              duration={500}
              offset={-80}
              onClick={handleDrawerToggle}
            >
              <Typography
                sx={{
                  marginLeft: "15px",
                  paddingLeft: "15px",
                  cursor: "pointer",
                  color: "white",
                  display: "block",
                  width: "80%",
                  pt: "10px",
                  pb: "4px",
                  borderBottom: "1px solid white",
                }}
              >
                {text}
              </Typography>
            </ScrollLink>
          )
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        sx={{
          background: scrolled ? "#11161F" : "transparent",
          color: "white",
          zIndex: 4,
          pb: 0.6,
          transition: "0.32s ease-in-out",
          boxShadow: "none",
        }}
        position="sticky"
      >
        <Toolbar>
          <Stack
            direction="row"
            display={{ xs: "flex", md: "none" }}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <IconButton size="large">
              <img
                src={isAPIRunning ? `${backendUrl}/${logo}` : logo}
                alt=""
                style={{ width: "70px" }}
              />
            </IconButton>
          </Stack>

          <Stack
            direction="row"
            display={{ xs: "none", md: "flex" }}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <IconButton size="large">
              <img
                src={isAPIRunning ? `${backendUrl}/${logo}` : logo}
                alt=""
                style={{ width: "100%", height: "71px" }}
              />
            </IconButton>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft="5%"
            >
              {["Home", "About Us", "Services", "Projects"].map((text) => (
                <ScrollLink
                  key={text}
                  to={text.toLowerCase().replace(" ", "-")}
                  smooth={true}
                  duration={500}
                  offset={text === "Projects" ? -90 : -80}
                >
                  <Typography
                    sx={{
                      margin: "0 15px",
                      cursor: "pointer",
                      color: "white",
                      display: "inline-block",
                      "&:hover": {
                        color: "#1c9ac0",
                      },
                    }}
                  >
                    {text}
                  </Typography>
                </ScrollLink>
              ))}

              <ScrollLink
                to={"contact-us"}
                smooth={true}
                duration={500}
                offset={-100}
              >
                <Button
                  sx={{
                    background: scrolled ? "#1c9ac0" : "white",
                    color: scrolled ? "#fff" : "#1c9ac0",
                    height: "42px",
                    minWidth: "150px",
                    px: 6,
                    borderRadius: 20,
                    "&:hover": {
                      background: "#1c9ac0",
                      color: "white",
                      opacity: 0.9,
                    },
                  }}
                  LinkComponent={Link}
                >
                  Lets Connect
                </Button>
              </ScrollLink>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "100%",
            background: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

const ListingComapanyDetails = () => {
  const auth = useContext(AuthContext);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { slug } = useParams();
  const [listing, setListing] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isAPIRunning, setIsAPIRunning] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [sendRole, setSendRole] = useState(null);
  const [isRoleSet, setIsRoleSet] = useState(false);

  useEffect(() => {
    console.log("listing updated", listing);
  }, [listing]);

  useEffect(() => {
    const storedUserRole = localStorage.getItem("userRole");
    console.log("storedUserRole", storedUserRole);
    if (storedUserRole) {
      setSendRole(parseInt(storedUserRole, 10));
    }
    setIsRoleSet(true); // Always set isRoleSet to true
  }, []);

  useEffect(() => {
    if (slug && isRoleSet) {
      fetchData(slug);
    }
  }, [slug, isRoleSet, type]);

  const fetchData = async (slug) => {
    try {
      setLoading(true);
      setError(false);

      // Construct URL based on type and sendRole
      let url;

      if (type === "listing" || type === null || type === undefined) {
        url = `${backendUrl}/api/places/website/${slug}`;
      } else if (type === "pendings") {
        url = `${backendUrl}/api/pendings/website/${slug}/${sendRole}`;
      } else {
        url = `${backendUrl}/api/places/website/${slug}`;
      }

      console.log("Constructed URL:", url);
      console.log("type:", type);

      const response = await axios.get(url);
      setListing(response.data.place);

      // Log after setting the state
      setIsAPIRunning(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      const filteredArray = DummyListing.filter((item) => item.slug === slug);
      setError(true);
      setListing(filteredArray[0]);
      setIsAPIRunning(false);
    } finally {
      setLoading(false);
    }
  };

  const formattedNumber = useFormattedPhoneNo(listing?.phone);

  useEffect(() => {
    if (!listing) {
      const filteredArray = DummyListing.filter((item) => item.slug === slug);
      setListing(filteredArray[0]);
      setIsAPIRunning(false);
    }
  }, [listing]);

  // if (!listing) {
  //   return <div>Listing not found</div>;
  // }
  console.log(listing, "ok");

  return (
    <Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography fontSize={"16px"} color="error" gutterBottom>
            No Listing Found
          </Typography>
          <IconButton aria-label="delete" size="medium">
            <ReplayIcon fontSize="inherit" onClick={fetchData} />
          </IconButton>
        </Box>
      ) : (
        <Grid>
          <CompanyTopbar
            formattedNumber={formattedNumber}
            phone={listing.phone}
            website={listing.website}
          />
          <Navbar
            logo={listing.businessLogo}
            Number={formattedNumber}
            isAPIRunning={isAPIRunning}
          />
          <Element name="home" style={{}}>
            <Header
              BgHead={listing?.businessBanner?.replace(/\\/g, "/")}
              isAPIRunning={isAPIRunning}
              title={listing.title}
              intro={listing.intro}
            />
          </Element>
          <Element name="about-us" style={{}}>
            <CompanyAboutUs
              banner={listing.image}
              title={listing.title}
              desc={listing.desc}
              intro={listing.aboutUs}
              aboutUs={listing.title}
              isAPIRunning={isAPIRunning}
            />
          </Element>
          <Element name="ban-text" style={{}}>
            <CompanyBanText
              banner={listing.image3}
              category={listing.category}
              formattedNumber={formattedNumber}
              phone={listing.phone}
              isAPIRunning={isAPIRunning}
            />
          </Element>
          <Element name="services" style={{}}>
            <CompanyCount
              banner={listing.image1}
              title={listing.title}
              desc={listing.desc}
              whyUs={listing.whyUs}
              isAPIRunning={isAPIRunning}
            />
          </Element>
          <Element
            name="services"
            style={{
              paddingTop: "16px",
            }}
          >
            <CompanyServices
              background={listing.image4?.replace(/\\/g, "/")}
              service={listing.accountingAndTaxService}
              isAPIRunning={isAPIRunning}
              phone={listing.phone}
            />
          </Element>

          <Element name="projects" style={{ padding: "100px 0 55px 0" }}>
            <CompanyLatestProject
              img={listing.image}
              img1={listing.image1}
              img2={listing.image2}
              img3={listing.image3}
              img4={listing.image4}
              imgB={listing.businessBanner?.replace(/\\/g, "/")}
              isAPIRunning={isAPIRunning}
              latestProjectIntro={listing.latestProjectIntro}
            />
          </Element>
          <Element name="" style={{}}>
            <CompanyAboutService
              background={listing.image2?.replace(/\\/g, "/")}
              banner={listing.image1}
              isAPIRunning={isAPIRunning}
              ourMission={listing.ourMission}
            />
          </Element>
          <Element name="contact-us" style={{ mt: "50px" }}>
            <ContactUs
              mapUrl={listing.mapUrl}
              category={listing.category}
              address={listing.address}
              website={listing.website}
              formattedNumber={formattedNumber}
              phone={listing.phone}
              contactUsIntro={listing.contactUsIntro}
            />
          </Element>
          <Element name="contact" style={{}}>
            <CompanyContactCrd
              address={listing.address}
              website={listing.website}
              formattedNumber={formattedNumber}
              phone={listing.phone}
              title={listing.title}
              logo={listing.businessLogo}
              isAPIRunning={isAPIRunning}
              intro={listing.intro}
            />
          </Element>
          <Element name="" style={{}}>
            <CompanyCopyRight />
          </Element>
        </Grid>
      )}
    </Grid>
  );
};

export default ListingComapanyDetails;
