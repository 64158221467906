import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import emailjs from "@emailjs/browser";
import { SnackbarProvider, useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  reviewInput: {
    background: "#f0f3f6",
    minHeight: "68px",
    padding: "0 20px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    fontSize: "20px",
    width: "100%",
    "&::placeholder": {
      color: "#818389",
      fontSize: "16px",
    },
  },
  reviewTextArea: {
    background: "#f0f3f6",
    padding: "30px 20px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    fontSize: "20px",
    "&::placeholder": {
      color: "#818389",
      fontSize: "16px",
      fontFamily: "poppins",
    },
    display: "block",
    resize: "vertical",
  },
}));

const FormC = ({ fields, buttonText, textAreaPlaceholder, onSubmit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [buttonTextState, setButtonTextState] = useState(buttonText);
  const formRef = useRef();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("xl"));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [textareaValue, setTextareaValue] = useState("");

  const initialFormState = fields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialFormState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "message") {
      setTextareaValue(value); 
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonTextState("Sending...");

    try {
      if (onSubmit) {
        await onSubmit(formData);

        enqueueSnackbar("Form submitted successfully!", {
          variant: "success",
          autoHideDuration: 2000,
          action: (key) => (
            <Button
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          ),
        });
      } else {
        const public_key = process.env.REACT_APP_EMAILJS_USER;
        const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

        await emailjs.sendForm(
          service_id,
          template_id,
          formRef.current,
          public_key
        );

        enqueueSnackbar("Form submitted successfully!", {
          variant: "success",
          autoHideDuration: 2000,
          action: (key) => (
            <Button
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          ),
        });
      }

      setFormData(initialFormState);
      setTextareaValue(""); 
      setButtonTextState(buttonText);
    } catch (error) {
      enqueueSnackbar(error.text || "An error occurred", {
        variant: "error",
        autoHideDuration: 2000,
        action: (key) => (
          <Button
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Close
          </Button>
        ),
      });
      setButtonTextState(buttonText);
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ mb: "10px" }}>
          {fields.map((field, index) => (
            <Grid
              sx={{ mb: "5px" }}
              item
              key={index}
              xs={10.7}
              md={
                index === 2 && fields.length % 2 !== 0
                  ? 12
                  : index === 0 || index === 1
                  ? 5.45
                  : 6
              }
              lg={
                index === 2 && fields.length % 2 !== 0
                  ? 12
                  : index === 0 || index === 1
                  ? 5.62
                  : 6
              }
              xl={
                index === 2 && fields.length % 2 !== 0
                  ? 12
                  : index === 0 || index === 1
                  ? 5.7
                  : 6
              }
            >
              <input
                type={field.type}
                name={field.name}
                required={field.required}
                placeholder={field.placeholder}
                className={classes.reviewInput}
                style={{
                  marginRight: "8px",
                  marginLeft: !isSmUp
                    ? "0"
                    : index === 1 && !isMdUp
                    ? "21%"
                    : index === 1 && !isLgUp
                    ? "14%"
                    : index === 1 && isLgUp
                    ? "11%"
                    : "0",
                }}
                value={formData[field.name]}
                onChange={handleChange}
              />
            </Grid>
          ))}
        </Grid>
        <Grid>
          <textarea
            rows={6}
            name="message"
            placeholder={textAreaPlaceholder}
            className={classes.reviewTextArea}
            style={{
              width: !isSmUp ? "89%" : "100%",
            }}
            value={textareaValue}
            onChange={handleChange}
          ></textarea>
        </Grid>
        <Button
          type="submit"
          sx={{
            background: "#1c9ac0",
            color: "white",
            ml: "8px",
            height: "64px",
            px: 3,
            borderRadius: 2,
            width: "auto",
            marginTop: "22px",
            "&:hover": {
              background: "#1c9ac0",
              color: "white",
              opacity: 0.9,
            },
          }}
        >
          {buttonTextState}
        </Button>
      </form>
    </>
  );
};

FormC.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired,
      placeholder: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  buttonText: PropTypes.string.isRequired,
  textAreaPlaceholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func, 
};

const EnhancedForm = (props) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    autoHideDuration={2000} 
  >
    <FormC {...props} />
  </SnackbarProvider>
);

export default EnhancedForm;
