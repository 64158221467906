import React from "react";
import StyledHader from "../components/Home/StyledHader";
import mapBg from "../assets/images/bg-map.png";
import { Grid, Paper, Box, Typography, Container } from "@mui/material";
import CategorySlider from "../components/Home/CategorySlider";
import SectionIntro from "../components/Home/SectionInto";
import CityCard from "../components/Home/CityCard";
import attachedBgImg from "../assets/images/bg-10.jpg";
import attachedBgColor from "../assets/images/bg-color.png";
import bgMap2 from "../assets/images/bg-map-2.png";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import TestimonialSlider from "../components/Home/TestimonialSlider";
import VideoSection from "../components/Home/VideoSection";
import FeaturedListing from "../components/Home/FeaturedListing";
import ProcessInfo from "../components/Home/ProcessInfo";

const Home = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <>
      <StyledHader />
      <Box
        component="section"
        sx={{
          backgroundImage: `url(${mapBg})`,
          backgroundPosition: "top left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            lg={8.8}
            sx={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <Paper
              elevation={3}
              sx={{
                marginTop: "20px",
                padding: "0 82px",
                boxShadow: " 0px 10px 60px 0px rgba(0,0,0,.05)",
                position: "relative",
                top: "-50px",
              }}
            >
              <CategorySlider />
            </Paper>
          </Grid>
        </Grid>
        <SectionIntro
          title="AROUND THE WORLD"
          subtitle="Worldwide Favorites"
          description="Experience the best of what your local offers."
        />
        <CityCard />
        <Box component="section" pt={9}>
          <Box
            component="section"
            sx={{
              backgroundImage: `url(${attachedBgImg})`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              padding: { xs: "3rem 0px 0rem 0", sm: "7rem 0px 0rem 0" },
              position: "relative",
              backgroundAttachment: "fixed",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${attachedBgColor})`,
                backgroundPosition: "50% -115px",
                backgroundRepeat: "no-repeat",
                opacity: 1,
                transition: "background .3s, border-radius .3s, opacity .3s",
                height: "100%",
                width: " 100%",
                top: 0,
                left: 0,
                position: "absolute",
              }}
            ></Box>
            <Container maxWidth="md">
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Sacramento",
                  color: "white",
                  fontSize: "70px",
                  position: "relative",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Totally Free{" "}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Barlow",
                  color: "white",
                  fontSize: { xs: "40px", sm: "60px", lg: "70px" },
                  position: "relative",
                  fontWeight: 800,
                  textAlign: "center",
                  lineHeight: { xs: "40px", sm: "70px", lg: "74px" },
                  marginTop: { xs: "0px", sm: "-16px" },
                }}
              >
                Zero Cost Business Listing with Prism X
              </Typography>
            </Container>
            <Box
              component="section"
              sx={{ position: "relative", zIndex: 1, top: "7rem" }}
            >
              <Box
                component="section"
                sx={{
                  background: "white",
                  borderTop: "10px solid #1c9ac0",
                  borderTopRightRadius: "5px",
                  width: { md: "100%", lg: "87%" },
                }}
              >
                <Grid container spacing={1} ref={ref}>
                  <Grid item sx={0} lg={1.5}></Grid>

                  <Grid
                    item
                    xs={12}
                    sm={2.9}
                    lg={2.6}
                    sx={{
                      borderRight: {
                        xs: "0px solid #dee2e8",
                        sm: "1px solid #dee2e8",
                      },
                      borderBottom: {
                        xs: "1px solid #dee2e8",
                        sm: "0px solid #dee2e8",
                      },
                      marginTop: "10px",
                      paddingBottom: { xs: "3rem", sm: "0px" },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontFamily: "Barlow",
                        color: "black",
                        fontSize: { xs: "30px", sm: "40px", md: "60px" },
                        position: "relative",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "72px",
                        paddingTop: { xs: "2rem", sm: "3rem", md: "5rem" },
                      }}
                    >
                      {inView && <CountUp end={8705} duration={2} />}
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Barlow",
                        color: "rgb(121, 127, 137)",
                        fontSize: "16px",
                        position: "relative",
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: "29px",
                      }}
                    >
                      Total Listings
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={2.9}
                    lg={2.6}
                    sx={{
                      borderRight: {
                        xs: "0px solid #dee2e8",
                        sm: "1px solid #dee2e8",
                      },
                      borderBottom: {
                        xs: "1px solid #dee2e8",
                        sm: "0px solid #dee2e8",
                      },
                      marginTop: "10px",
                      paddingBottom: { xs: "3rem", sm: "0px" },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontFamily: "Barlow",
                        color: "black",
                        fontSize: { xs: "30px", sm: "40px", md: "60px" },
                        position: "relative",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "72px",
                        paddingTop: { xs: "2rem", sm: "3rem", md: "5rem" },
                      }}
                    >
                      {inView && <CountUp end={480} duration={2} />}
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Barlow",
                        color: "rgb(121, 127, 137)",
                        fontSize: "16px",
                        position: "relative",
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: "29px",
                      }}
                    >
                      Company Staff
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={2.9}
                    lg={2.6}
                    sx={{
                      borderRight: {
                        xs: "0px solid #dee2e8",
                        sm: "1px solid #dee2e8",
                      },
                      borderBottom: {
                        xs: "1px solid #dee2e8",
                        sm: "0px solid #dee2e8",
                      },
                      marginTop: "10px",
                      paddingBottom: { xs: "3rem", sm: "0px" },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontFamily: "Barlow",
                        color: "black",
                        fontSize: { xs: "30px", sm: "40px", md: "60px" },
                        position: "relative",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "72px",
                        paddingTop: { xs: "2rem", sm: "3rem", md: "5rem" },
                      }}
                    >
                      {inView && <CountUp end={6260} duration={2} />}
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Barlow",
                        color: "rgb(121, 127, 137)",
                        fontSize: "16px",
                        position: "relative",
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: "29px",
                      }}
                    >
                      Places in the World
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={2.9}
                    lg={2.6}
                    sx={{ marginTop: "10px" }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontFamily: "Barlow",
                        color: "black",
                        fontSize: { xs: "30px", sm: "40px", md: "60px" },
                        position: "relative",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "72px",
                        paddingTop: { xs: "2rem", sm: "3rem", md: "5rem" },
                      }}
                    >
                      {inView && <CountUp end={9774} duration={2} />}
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Barlow",
                        color: "rgb(121, 127, 137)",
                        fontSize: "16px",
                        position: "relative",
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: "29px",
                      }}
                    >
                      Happy People
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <FeaturedListing />
      <Box
        component="section"
        sx={{
          backgroundColor: "#f0f3f6",
          backgroundImage: `url(${bgMap2})`,
          backgroundPosition: "top left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box py={5}>
          <SectionIntro
            title="OUR TESTIMONIALS"
            subtitle="What They Say"
            description="Prism X Experts transformed your online presence, boosting traffic and conversions beyond your expectations with local business listings and directory."
          />
          <TestimonialSlider />
        </Box>
      </Box>

      <VideoSection />
      <ProcessInfo />
    </>
  );
};

export default Home;
