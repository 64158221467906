import React from "react";
import TitleHeading from "../UI/TitleHeading";
import { Grid, Box, Typography, TextField } from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import Form from "../UI/Form";

const Review = () => {
  const handleFeedbackSubmit = async (formData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const fields = [
    {
      type: "text",
      placeholder: "Enter your name",
      name: "user_name",
      required: true,
    },
    {
      type: "email",
      placeholder: "Enter your email",
      name: "user_email",
      required: true,
    },
  ];

  return (
    <>
      <TitleHeading text="Write a Review" pt={4} mb={3} />

      <Grid spacing={2} container sx={{ fontFamily: "poppins" }}>
        <Grid item xs={12} sm={6}>
          <Box
            component="section"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box component="section">
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#11161f",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "left",
                  paddingLeft: "3px",
                }}
              >
                Quality{" "}
              </Typography>
              <Box component="section">
                {" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />
              </Box>
            </Box>
            <Box component="section">
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#11161f",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "left",
                  paddingLeft: "3px",
                }}
              >
                Hospitality{" "}
              </Typography>
              <Box component="section">
                {" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />
              </Box>
            </Box>
          </Box>
          <Box
            component="section"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <Box component="section">
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#11161f",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "left",
                  paddingLeft: "3px",
                }}
              >
                Service{" "}
              </Typography>
              <Box component="section">
                {" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />
              </Box>
            </Box>
            <Box component="section">
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#11161f",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "32px",
                  textAlign: "left",
                  paddingLeft: "3px",
                }}
              >
                Pricing{" "}
              </Typography>
              <Box component="section">
                {" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />{" "}
                <StarRateIcon sx={{ color: "#ffa60c" }} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            component="section"
            sx={{
              background: "#f0f3f6",
              width: "200px",
              display: "flex",
              marginLeft: { xs: "0", sm: "auto" },
              borderRadius: "4px",
              justifyContent: "center",
              padding: "45px 20px",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#1c9ac0",
                  fontWeight: 600,
                  fontSize: "30px",
                  lineHeight: "32px",
                  textAlign: "Center",
                }}
              >
                5.00
              </Typography>
              <Typography
                variant="h5"
                color="black"
                sx={{
                  color: "#11161f",
                  fontWeight: 400,
                  fontSize: "17px",
                  lineHeight: "32px",
                  textAlign: "left",
                  paddingLeft: "3px",
                }}
              >
                Average Ratting
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box component="section" pt={6} pr={4}>
        <Typography
          variant="h6"
          color="black"
          sx={{
            color: "#818389",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "32px",
            textAlign: "left",
            paddingLeft: "3px",
            marginBottom: "20px",
          }}
        >
          Your email address will not be published.{" "}
        </Typography>

        <Form
          fields={fields}
          buttonText="SEND REVIEW"
          textAreaPlaceholder="Write Your Review"
          onSubmit={handleFeedbackSubmit}
          sx={{ marginTop: "10px" }}
        />
      </Box>
    </>
  );
};

export default Review;
