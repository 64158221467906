import React from "react";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FooterHeading from "../FooterHeading";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import bgImage from "../.././assets/images/bg-footer-1.png";

const CompanyContactCrd = ({
  address,
  website,
  phone,
  title,
  logo,
  isAPIRunning,
  intro,
  formattedNumber,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleClick = (label) => {
    if (label === "Visit Our Website") {
      window.location.href = `${website}`;
    } else if (label === "Call Us") {
      window.location.href = `tel:${phone}`;
    }
  };

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const truncatedIntro = truncateText(intro, 100);
  const shouldHide = website === "https://www.example.com/";

  return (
    <Grid
      container
      spacing={5}
      sx={{
        background: "white",
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "top left",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        overflow: "visible",
        bgcolor: "#11161f",
        height: "auto",
      }}
      mt={{ md: 10, sm: 2, xs: 2 }}
      pt={{ xs: 4, md: 5 }}
      pb={{ xs: 6, md: 6 }}
      px={{ lg: 30, md: 15, sm: 5, xs: 2 }}
    >
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "43px",
            lineHeight: "50px",
            fontFamily: "Barlow",
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "800",
            marginTop: "-20px",
            mb: "30px",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3.4}>
        <Box mb={2} mt={-2} sx={{ textAlign: "left" }}>
          <img
            src={isAPIRunning ? `${backendUrl}/${logo}` : `${logo}`}
            alt=""
            style={{ width: "100px", textAlign: "left" }}
          />
        </Box>

        <Typography
          variant="body2"
          color="grey.400"
          sx={{
            fontSize: "15px",
            lineHeight: "30px",
            paddingBottom: "15px",
            fontFamily: "'Roboto', sans-serif",
            textAlign: "left",
            width: { lg: "250px", md: "150px" },
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "90px",
          }}
        >
          {truncatedIntro}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2.4}>
        <Box mb={2} mt={-2} sx={{ textAlign: "left" }}>
          <FooterHeading Text="Our Mission" linebg="#1c9ac0" />
        </Box>
        <Typography
          variant="body2"
          color="grey.400"
          sx={{
            fontSize: "15px",
            lineHeight: "30px",
            paddingBottom: "15px",
            paddingTop: "8px",
            fontFamily: "'Roboto', sans-serif",
            textAlign: "left",
          }}
        >
          At the heart of everything we do lies a steadfast commitment to
          excellence and innovation. Our mission is to empower and inspire
          individuals and communities.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Box mb={2} mt={-2} sx={{ textAlign: "left" }}>
          <FooterHeading Text="Why Us?" linebg="#1c9ac0" />
        </Box>
        <ul style={{ padding: "0px" }}>
          <li
            style={{
              color: "#BDBDBD",
              textAlign: "left",
              marginBottom: "21px",
              marginLeft: "15px",
              transition: "transform 0.3s",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "'Roboto', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "translateX(5px)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.transform = "translateX(0px)")
            }
          >
            High Quality: We deliver superior products and services.
          </li>
          <li
            style={{
              color: "#BDBDBD",
              textAlign: "left",
              marginBottom: "21px",
              marginLeft: "15px",
              transition: "transform 0.3s",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "'Roboto', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "translateX(5px)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.transform = "translateX(0px)")
            }
          >
            Customer Focused: Your needs are our priority.
          </li>
          <li
            style={{
              color: "#BDBDBD",
              textAlign: "left",
              marginBottom: "21px",
              marginLeft: "15px",
              transition: "transform 0.3s",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "'Roboto', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "translateX(5px)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.transform = "translateX(0px)")
            }
          >
            Innovative: We use the latest technologies.
          </li>
          <li
            style={{
              color: "#BDBDBD",
              textAlign: "left",
              marginBottom: "21px",
              marginLeft: "15px",
              transition: "transform 0.3s",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "'Roboto', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "translateX(5px)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.transform = "translateX(0px)")
            }
          >
            Reliable: Trusted by clients for our consistency.
          </li>
        </ul>
      </Grid>

      <Grid item xs={12} sm={4} md={3.2}>
        <Box mb={2} mt={-2} sx={{ textAlign: "left" }}>
          <FooterHeading Text="Contact Us" linebg="#1c9ac0" />
        </Box>
        <div style={{ paddingTop: "0px" }}>
          <Box sx={{ display: "flex" }}>
            <LocationOnIcon
              sx={{
                color: "#abafb5",

                marginBottom: 0,
                marginTop: "16px",
                mr: 1,
              }}
            />
            <p
              style={{
                color: "#abafb5",
                marginBottom: "0px",
                fontSize: "15px",
                lineHeight: "24px",
                fontFamily: "'Roboto', sans-serif",
                textAlign: "left",
              }}
            >
              {address}
            </p>
          </Box>
          {!shouldHide && (
            <Box sx={{ display: "flex" }}>
              <LanguageIcon
                sx={{
                  color: "#abafb5",
                  marginBottom: "0px",
                  marginTop: "28px",
                  mr: 1,
                }}
              />
              <a
                href={`${website}`}
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "#abafb5",
                  marginTop: "30px",
                  fontFamily: "'Roboto', sans-serif",
                  textAlign: "left",
                }}
              >
                {website}
              </a>
            </Box>
          )}
          <a
            href={`tel:${phone}`}
            style={{ textDecoration: "none", color: " unset" }}
          >
            <Box sx={{ display: "flex" }}>
              <CallIcon
                sx={{
                  color: "#abafb5",
                  marginBottom: "0px",
                  marginTop: "28px",
                  mr: 1,
                  cursor: "pointer",
                }}
              />
              <p
                style={{
                  color: "#abafb5",
                  marginBottom: "0px",
                  marginTop: "30px",
                  fontFamily: "'Roboto', sans-serif",
                  cursor: "pointer",
                }}
              >
                {formattedNumber}
              </p>
            </Box>
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default CompanyContactCrd;
