import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Container,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./../../../context/auth-context";
import { PendingCounterContext } from "./../../../context/pending-counter";

import ReplayIcon from "@mui/icons-material/Replay";
import DummyListing from "../../Data/DummyListing";
import DashboardPendingSearch from "./DashboardPendingSearch";
import PendingCard from "./PendingCard";

const DashboardPending = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const auth = useContext(AuthContext);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [accNTaxService, setAccNTaxService] = useState([]);
  const [area, setArea] = useState([]);
  const [region, setRegion] = useState("");
  const [isAPIRunning, setIsAPIRunning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const { counter, setCounter } = useContext(PendingCounterContext);

  const handleDeleteItem = (id) => {
    fetch(`${backendUrl}/api/pendings/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const updatedData = items.filter((item) => item.id !== id);
        setItems(updatedData);
        setFilteredItems(updatedData);
        setCounter(counter - 1);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setItems(DummyListing);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const itemsPerPage = 12;

  const fetchData = async (page) => {
    try {
      setLoading(true);
      setError(false);
      setIsAPIRunning(true);

      let url;
      if (auth.userRole == 1) {
        url = `${backendUrl}/api/pendings/?page=${page}&limit=${itemsPerPage}`;
      } else {
        url = `${backendUrl}/api/pendings/users/${auth.userId}?page=1&limit=${itemsPerPage}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setResMsg(errorData.message);
        throw new Error(errorData.message || "Network response was not ok");
      }

      const data = await response.json();
      setItems(data.places);
      setFilteredItems(data.places);
      setTotalPages(data.totalPages);
    } catch (err) {
      setItems(DummyListing);
      setIsAPIRunning(false);
      console.error("Error fetching data:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, setCounter]);

  return (
    <Grid
      container
      spacing={3}
      sx={{ overflow: "scroll", height: "100vh", pb: 5 }}
    >
      <DashboardPendingSearch
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        propertyType={propertyType}
        setPropertyType={setPropertyType}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data={filteredItems}
        category={category}
        setCategory={setCategory}
        setCity={setCity}
        city={city}
        item={items}
        setItems={setItems}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        accNTaxService={accNTaxService}
        setAccNTaxService={setAccNTaxService}
        area={area}
        setArea={setArea}
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        setRegion={setRegion}
        region={region}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
            ml: "700px",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
            ml: "550px",
          }}
        >
          <Typography fontSize={"16px"} color="error" gutterBottom>
            Error fetching Listings, Please try again.
          </Typography>
          <IconButton aria-label="delete" size="medium">
            <ReplayIcon fontSize="inherit" onClick={fetchData} />
          </IconButton>
        </Box>
      ) : (
        <>
          <PendingCard
            items={items}
            handleDeleteItem={handleDeleteItem}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setItems={setItems}
            totalPages={totalPages}
            resMsg={resMsg}
            isAPIRunning={isAPIRunning}
            authRole={auth.userRole}
            fetchData={fetchData}
          />
        </>
      )}
    </Grid>
  );
};

export default DashboardPending;
