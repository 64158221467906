import React from "react";
import { Grid, Typography } from "@mui/material";

const Journey = () => {
  return (
    <Grid
      container
      sx={{
        display: "block",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        py: 10,
        px: { md: 44, sm: 20, xs: 5 },
      }}
    >
      <Typography
        sx={{
          color: "#1c9ac0",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "3px",
          textAlign: "center",
          fontFamily: "Barlow",
        }}
      >
        GET TO KNOW ABOUT US
      </Typography>
      <Typography
        sx={{
          fontSize: "46px",
          fontWeight: "800",
          lineHeight: "1",
          color: "#161c26",
          mb: "10px",
          textAlign: "center",
          fontFamily: "Barlow",
        }}
      >
        Prism X's Inspiring Journey
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: "grey",
          textAlign: "center",
          lineHeight: "1.8",
        }}
      >
        PrismX reignites community bonds by simplifying the discovery of
        authentic local services in the digital age's maze. Our platform is a
        haven for interaction and advocacy for top-tier local businesses across
        the USA.
      </Typography>
    </Grid>
  );
};

export default Journey;
