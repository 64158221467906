import realStateLogo from "../../assets/images/agreement.png";
import shoppingLogo from "../../assets/images/online-shopping.png";

export const data = [
  {
    id: 1,
    title: "UnitedState APR 2024 | Good ROI | Low Floor",
    image:
      "https://img.freepik.com/free-photo/old-wooden-house-village_1268-14691.jpg?t=st=1716905419~exp=1716909019~hmac=b41fb3cb399bff566add1adfec1be38d51a36008f8a3c8539eb25941aeea3a83&w=1060",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 7,
    phone: "032353332434",
    website: "https://youtube.com",
    category: "Shopping",
    city: "Blackpool",
    priceRange: "Inexpensive",
    accountingAndTaxService: ["Payroll Services"],
    areas: ["Arlington", "Baltimore"],
    slug: "uniedstate-apr-2024-good-roi",
    image1:
      "https://img.freepik.com/free-photo/shiny-night-city_1127-8.jpg?t=st=1716905101~exp=1716908701~hmac=5be823f08ec1b36c9ecf5095a1ccf567c5775b5105db74484b29411db2b1247c&w=1060",
    image2:
      "https://img.freepik.com/free-photo/pipe-transport-garage-concrete-vehicle_1127-2367.jpg?t=st=1716905127~exp=1716908727~hmac=8b8612a1a67ddd6a2aa80fe1a2878503ed32e19bef69a4167165066f5a83604e&w=1060",
    image3:
      "https://img.freepik.com/free-photo/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge_1258-150749.jpg?t=st=1716905373~exp=1716908973~hmac=0bd9b26d778ac42fa23ec84737d96c52f73dacb3ba45788b4a65a8a961e8b481&w=1060",
    image4:
      "https://img.freepik.com/free-photo/shiny-night-city_1127-8.jpg?t=st=1716905101~exp=1716908701~hmac=5be823f08ec1b36c9ecf5095a1ccf567c5775b5105db74484b29411db2b1247c&w=1060",
    aboutBusiness:
      "what you see specialists at Dubai Marina, We at Key One Realty Llc are proud to offer you this fully furnished 1-bedroom apartment for sale, offering an excellent investment opportunity, and as a personal retreat apartment. Apartment Details: • Modern Design providing a stylish and comfortable living space. • Fully Furnished • Swimming Pool • Gymnasium • 24/7 Security • Studio One is ideally located in the vibrant Dubai Marina, • High Rental Demand • Capital Appreciation Don't miss the chance to own this attractive property. * For more details & Viewing Call Us* Studio One Tower is a 32-storey residential building located in Dubai Marina, Dubai. The building is located in a prime location in Dubai Marina, close to Bluewaters Island and the Wyndham hotel. It is within walking distance of water taxis, bicycles, the tram, metro, RTA buses and taxi stops, and has easy access to Sheikh Zayed Road.",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423292.5449883026!2d-119.03562790052547!3d34.01890379937177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1716907757480!5m2!1sen!2s",

    businessLogo:
      "https://cdn.dribbble.com/users/6621596/screenshots/16381347/media/6b58ee2bf64293913882b86c41d19b92.jpg?resize=400x300&vertical=center",

    businesslogoAvatar: realStateLogo,
  },
  {
    id: 2,
    title: "USA APR 2024 | Good ROI | Low Floor",
    image:
      "https://img.freepik.com/free-photo/pipe-transport-garage-concrete-vehicle_1127-2367.jpg?t=st=1716905127~exp=1716908727~hmac=8b8612a1a67ddd6a2aa80fe1a2878503ed32e19bef69a4167165066f5a83604e&w=1060",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 7,
    phone: "032353332434",
    website: "https://youtube.com",
    category: "Shopping",
    city: "Blackpool",
    priceRange: "Inexpensive",
    accountingAndTaxService: ["Payroll Services"],
    areas: ["Arlington", "Baltimore"],
    slug: "usa-apr-2024-good-roi",
    image1:
      "https://img.freepik.com/free-photo/shiny-night-city_1127-8.jpg?t=st=1716905101~exp=1716908701~hmac=5be823f08ec1b36c9ecf5095a1ccf567c5775b5105db74484b29411db2b1247c&w=1060",
    image2:
      "https://img.freepik.com/free-photo/pipe-transport-garage-concrete-vehicle_1127-2367.jpg?t=st=1716905127~exp=1716908727~hmac=8b8612a1a67ddd6a2aa80fe1a2878503ed32e19bef69a4167165066f5a83604e&w=1060",
    image3:
      "https://img.freepik.com/free-photo/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge_1258-150749.jpg?t=st=1716905373~exp=1716908973~hmac=0bd9b26d778ac42fa23ec84737d96c52f73dacb3ba45788b4a65a8a961e8b481&w=1060",
    image4:
      "https://img.freepik.com/free-photo/shiny-night-city_1127-8.jpg?t=st=1716905101~exp=1716908701~hmac=5be823f08ec1b36c9ecf5095a1ccf567c5775b5105db74484b29411db2b1247c&w=1060",
    aboutBusiness:
      "Being Area specialists at Dubai Marina, We at Key One Realty Llc are proud to offer you this fully furnished 1-bedroom apartment for sale, offering an excellent investment opportunity, and as a personal retreat apartment. Apartment Details: • Modern Design providing a stylish and comfortable living space. • Fully Furnished • Swimming Pool • Gymnasium • 24/7 Security • Studio One is ideally located in the vibrant Dubai Marina, • High Rental Demand • Capital Appreciation Don't miss the chance to own this attractive property. * For more details & Viewing Call Us* Studio One Tower is a 32-storey residential building located in Dubai Marina, Dubai. The building is located in a prime location in Dubai Marina, close to Bluewaters Island and the Wyndham hotel. It is within walking distance of water taxis, bicycles, the tram, metro, RTA buses and taxi stops, and has easy access to Sheikh Zayed Road.",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97227.44525628735!2d-74.38536796641993!3d40.40092675429588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3cdd2fff294a3%3A0x4a7f83ee8a944499!2sOld%20Bridge%2C%20NJ%2C%20USA!5e0!3m2!1sen!2s!4v1716911579784!5m2!1sen!2s",

    businessLogo:
      "https://bcassetcdn.com/public/blog/wp-content/uploads/2023/01/16210417/handshake-real-estate-by-ernestjdx-brandcrowd.png",
    businesslogoAvatar: shoppingLogo,
  },
  {
    id: 3,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-26/653a4abdb9ee8-2023-10-26/653a4abdb9ee8-2023-10-26-1-0.jpeg",
    description:
      "Click here to see the data for yourself at our Notion databases. You'll find experiences, data for yourself data for yourself",
    path: "https://spiritualdata.notion.site/Spiritual-Data-7d4fd0cf93454243997820ae6ff0836e",
    listFor: "Open",
    type: "Office",
    price: "1500$",
    location: "Karachi One, Dubai Marina , Dubai",
    bed: 5,
    bathroom: 1,
    phone: "032353332434",
    website: "https://youtube.com",
    category: "Restaurants",
    city: "Brishtol",
    priceRange: "Moderate",
    accountingAndTaxService: ["Accountants"],
    areas: ["Austin", "Baltimore"],
    slug: "newyork-apr-2024-good-roi",
    businessLogo: "https://www.logodee.com/wp-content/uploads/2021/10/28.jpg",
    businesslogoAvatar: realStateLogo,
  },
  {
    id: 4,
    title: "Follow our Newsletter",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-09-29/651663cfebf6b-2023-09-29/651663cfebf6b-2023-09-29-1-9.jpeg",
    description:
      "Stay up to date on Spiritual Data's journey, new product features, and the latest insights from our truth analysis.",
    path: "https://spiritualdata.beehiiv.com",
    listFor: "Open",
    type: "Hotel",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 10,
    bathroom: 2,
    phone: "032353332434",
    category: "Plumbers",
    city: "Blackpool",
    priceRange: "Pricey",
    accountingAndTaxService: ["Accountants"],
    areas: ["Baltimore"],
    slug: "karachi-apr-2024-good-roi",
    businessLogo: "https://www.logodee.com/wp-content/uploads/2021/10/28.jpg",
    businesslogoAvatar: shoppingLogo,
  },
  {
    id: 5,
    title: "Donate",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Penthouse",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 4,
    phone: "032353332434",
    website: "https://youtube.com",
    category: "Nightlife",
    city: "California",
    priceRange: "Ultra High",
    accountingAndTaxService: ["Payroll Services"],
    areas: ["Arlington", "Baltimore"],
    businessLogo: "https://www.logodee.com/wp-content/uploads/2021/10/28.jpg",
  },
  {
    id: 6,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 7,
    phone: "032353332434",
    category: "Locksmiths",
    city: "Chicago",
    priceRange: "Ultra High",
    accountingAndTaxService: ["Accounting Services"],
    areas: ["Baltimore"],
  },
  {
    id: 7,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-26/653a4abdb9ee8-2023-10-26/653a4abdb9ee8-2023-10-26-1-0.jpeg",
    description:
      "Click here to see the data for yourself at our Notion databases. You'll find experiences, data for yourself",
    path: "https://spiritualdata.notion.site/Spiritual-Data-7d4fd0cf93454243997820ae6ff0836e",
    listFor: "Open",
    type: "Office",
    price: "1500$",
    location: "Karachi One, Dubai Marina , Dubai",
    bed: 5,
    bathroom: 1,
    category: "Home Decor",
    city: "New Jersey",
    priceRange: "Pricey",
    accountingAndTaxService: ["Payroll Services"],
    areas: ["Boca Raton"],
  },
  {
    id: 8,
    title: "Follow our Newsletter",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-09-29/651663cfebf6b-2023-09-29/651663cfebf6b-2023-09-29-1-9.jpeg",
    description:
      "Stay up to date on Spiritual Data's journey, new product features, and the latest insights from our truth analysis.",
    path: "https://spiritualdata.beehiiv.com",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 10,
    bathroom: 2,
    category: "Fitness",
    city: "New York",
    accountingAndTaxService: ["Tax Services"],
    areas: ["Baltimore"],
  },
  {
    id: 9,
    title: "Donate",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Office",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 4,
    category: "Home Decor",
    city: "Blackpool",
  },
  {
    id: 10,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 7,
  },
  {
    id: 11,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-26/653a4abdb9ee8-2023-10-26/653a4abdb9ee8-2023-10-26-1-0.jpeg",
    description:
      "Click here to see the data for yourself at our Notion databases. You'll find experiences, data for yourself",
    path: "https://spiritualdata.notion.site/Spiritual-Data-7d4fd0cf93454243997820ae6ff0836e",
    listFor: "Open",
    type: "Office",
    price: "1500$",
    location: "Karachi One, Dubai Marina , Dubai",
    bed: 5,
    bathroom: 1,
  },
  {
    id: 12,
    title: "Follow our Newsletter",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-09-29/651663cfebf6b-2023-09-29/651663cfebf6b-2023-09-29-1-9.jpeg",
    description:
      "Stay up to date on Spiritual Data's journey, new product features, and the latest insights from our truth analysis.",
    path: "https://spiritualdata.beehiiv.com",
    listFor: "Open",
    type: "Penthouse",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 10,
    bathroom: 2,
  },
  {
    id: 13,
    title: "Donate",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 4,
  },
  {
    id: 14,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 7,
  },
  {
    id: 15,
    title: "Tenanted Till APR 2024 | Good ROI | Low Floor",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-26/653a4abdb9ee8-2023-10-26/653a4abdb9ee8-2023-10-26-1-0.jpeg",
    description:
      "Click here to see the data for yourself at our Notion databases. You'll find experiences, data for yourself",
    path: "https://spiritualdata.notion.site/Spiritual-Data-7d4fd0cf93454243997820ae6ff0836e",
    listFor: "Open",
    type: "Office",
    price: "1500$",
    location: "Karachi One, Dubai Marina , Dubai",
    bed: 5,
    bathroom: 1,
    category: "Beauty",
    aboutBusiness:
      "what you see specialists at Dubai Marina, We at Key One Realty Llc are proud to offer you this fully furnished 1-bedroom apartment for sale, offering an excellent investment opportunity, and as a personal retreat apartment. Apartment Details: • Modern Design providing a stylish and comfortable living space. • Fully Furnished • Swimming Pool • Gymnasium • 24/7 Security • Studio One is ideally located in the vibrant Dubai Marina, • High Rental Demand • Capital Appreciation Don't miss the chance to own this attractive property. * For more details & Viewing Call Us* Studio One Tower is a 32-storey residential building located in Dubai Marina, Dubai. The building is located in a prime location in Dubai Marina, close to Bluewaters Island and the Wyndham hotel. It is within walking distance of water taxis, bicycles, the tram, metro, RTA buses and taxi stops, and has easy access to Sheikh Zayed Road.",
  },
  {
    id: 16,
    title: "Follow our Newsletter",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-09-29/651663cfebf6b-2023-09-29/651663cfebf6b-2023-09-29-1-9.jpeg",
    description:
      "Stay up to date on Spiritual Data's journey, new product features, and the latest insights from our truth analysis.",
    path: "https://spiritualdata.beehiiv.com",
    listFor: "Open",
    type: "Penthouse",
    price: "5500$",
    location: "Studio One, Dubai Marina , Dubai",
    bed: 10,
    bathroom: 2,
    aboutBusiness:
      "what you see specialists at Dubai Marina, We at Key One Realty Llc are proud to offer you this fully furnished 1-bedroom apartment for sale, offering an excellent investment opportunity, and as a personal retreat apartment. Apartment Details: • Modern Design providing a stylish and comfortable living space. • Fully Furnished • Swimming Pool • Gymnasium • 24/7 Security • Studio One is ideally located in the vibrant Dubai Marina, • High Rental Demand • Capital Appreciation Don't miss the chance to own this attractive property. * For more details & Viewing Call Us* Studio One Tower is a 32-storey residential building located in Dubai Marina, Dubai. The building is located in a prime location in Dubai Marina, close to Bluewaters Island and the Wyndham hotel. It is within walking distance of water taxis, bicycles, the tram, metro, RTA buses and taxi stops, and has easy access to Sheikh Zayed Road.",
  },
  {
    id: 17,
    title: "Fully Furnished | Downtown View | Best Price ",
    image:
      "https://keyone.s3.me-south-1.amazonaws.com/thumb/2023-10-12/6527ee9a7df5b-2023-10-12/6527ee9a7df5b-2023-10-12-1-7.jpg",
    description:
      "Spiritual Data is a US-based nonprofit. Your donations fund refining our methods for calculating truth,",
    path: "/donations",
    listFor: "Open",
    type: "Apartment",
    price: "5500$",
    location: "Pakistan Cant, Dubai Marina , Dubai",
    bed: 2,
    bathroom: 4,
    aboutBusiness:
      "what you see specialists at Dubai Marina, We at Key One Realty Llc are proud to offer you this fully furnished 1-bedroom apartment for sale, offering an excellent investment opportunity, and as a personal retreat apartment. Apartment Details: • Modern Design providing a stylish and comfortable living space. • Fully Furnished • Swimming Pool • Gymnasium • 24/7 Security • Studio One is ideally located in the vibrant Dubai Marina, • High Rental Demand • Capital Appreciation Don't miss the chance to own this attractive property. * For more details & Viewing Call Us* Studio One Tower is a 32-storey residential building located in Dubai Marina, Dubai. The building is located in a prime location in Dubai Marina, close to Bluewaters Island and the Wyndham hotel. It is within walking distance of water taxis, bicycles, the tram, metro, RTA buses and taxi stops, and has easy access to Sheikh Zayed Road.",
  },
];
