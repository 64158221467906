import React from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import selectIcon from "../../assets/images/select (2).png";
import TargetIcon from "../../assets/images/target.png";
import ChooseIcon from "../../assets/images/choose.png";
import worldIcon from "../../assets/images/earth-asia.png";

const ProcessInfo = () => {
  const gridItems = [
    {
      image: selectIcon,
      title: "Pick A Category",
      description:
        "Start by choosing from an array of categories that cover every possible need you might have.",
    },
    {
      image: TargetIcon,
      title: "Discover What You Need",
      description: "Use our intuitive search to uncover top-rated businesses.",
    },
    {
      image: ChooseIcon,
      title: "Choose Your Favorite",
      description: "Use our intuitive search to uncover top-rated businesses.",
    },
    {
      image: worldIcon,
      title: "Step Out and Experience",
      description:
        "Go out and enjoy all the services and products your chosen business has to offer.",
    },
  ];

  return (
    <>
      {" "}
      <Container maxWidth="lg" sx={{ pt: "2.5rem", pb: "4rem" }}>
        <Grid spacing={2} container>
          {gridItems.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                padding: { xs: "5px", sm: "9px", md: "40px" },
                marginBottom: { xs: "40px", md: "0px" },
              }}
            >
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                sx={{
                  width: "24%",
                  display: "flex",
                  margin: "auto",
                  animation: "beat 1.5s ease 0s infinite",
                }}
              />
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontWeight: "600",
                  letterSpacing: "1px",
                  lineHeight: "30px",
                  textAlign: "center",
                  marginTop: "22px",
                  fontFamily: "Barlow",
                  fontSize: "22px",
                  marginBottom: "20px",
                  color: "#11161f",
                  padding: "0px 34px",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontWeight: "400",
                  letterSpacing: "1px",
                  lineHeight: "25px",
                  textAlign: "center",
                  marginTop: "10px",
                  fontFamily: "sans-serif",
                  fontSize: "16px",
                  marginBottom: "10px",
                  color: "#888888",
                }}
              >
                {item.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
      <style jsx global>{`
        @keyframes beat {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(0.82);
            transform: scale(0.82);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      `}</style>
    </>
  );
};

export default ProcessInfo;
