import { Container, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CompanyCopyRight = () => {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        mt: "auto",
        textAlign: "center",
        py:1
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="textSecondary" sx={{fontSize:"12px",color:"#BDBDBD"}}>
          Copyright © 2023. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default CompanyCopyRight;