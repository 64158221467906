import { useState, useEffect } from "react";

const useFormattedPhoneNo = (number) => {
  const [formattedNumber, setFormattedNumber] = useState("");

  useEffect(() => {
    if (number) {
      const formatPhoneNumber = (num) => {
        const numberString = num?.toString();
        return numberString?.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      };
      setFormattedNumber(formatPhoneNumber(number));
    } else {
      setFormattedNumber("no number available");
    }
  }, [number]);

  return formattedNumber;
};

export default useFormattedPhoneNo;
