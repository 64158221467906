import { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import PinterestIcon from "@mui/icons-material/Pinterest";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Container, Grid, IconButton, Menu } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CompanyContactData from "../components/Data/CompanyContactInfo";
import Modal from "./UI/Modal";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import LoginForm from "./UI/LoginForm";
import { AuthContext } from "../context/auth-context";
import RegisterForm from "./UI/RegisterForm";
import ResetPasswordForm from "./UI/ResetPasswordForm";

const { pinterest, instagram, facebook } = CompanyContactData[0];
const useStyles = makeStyles((theme) => ({
  userHover: {
    "&:hover": {
      color: "#1c9ac0",
      opacity: "1",
    },
    opacity: "0.5",
  },
  userIconHover: {
    "&:hover": {
      opacity: "1",
    },
    opacity: "0.5",
  },
  paired: {
    "&:hover": {
      opacity: "1",
    },
    opacity: "0.5",
    cursor: "pointer",
  },
  rightPair: {
    position: "relative",
    top: "2px",
  },
}));

const styleForIcon = {
  fontSize: "19px",
};

const styleForIconUser = {
  fontSize: "22px",
};

const styleForIconTxt = {
  top: "-4px",
  position: "relative",
  marginLeft: "11px",
  fontSize: "14px",
  cursor: "pointer",
  fontFamily: "sans-serif",
};

const styleForIconSearch = {
  fontSize: "28px",
  position: "absolute",
  left: "-25px",
  top: "-1px",
};

const Topbar = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const searchBoxRef = useRef(null);
  const UserModelRef = useRef(null);
  const auth = useContext(AuthContext);
  const [isSearch, setIsSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [formType, setFormType] = useState("signin");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsSearch(false);
  }, [location]);

  const showSearch = () => {
    setIsSearch(!isSearch);
  };

  const searchHandler = () => {
    navigate("/listings", { state: { searchInput } });
    setSearchInput("");
    setIsSearch(false);
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOpenModal = (type) => {
    setFormType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchHandler();
    }
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setIsSearch(false);
    }
  };

  const handleClickOutsideModal = (event) => {
    if (UserModelRef.current && !UserModelRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearch]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutsideModal);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [isModalOpen]);
  return (
    <Box
      sx={{ bgcolor: "grey.900", color: "grey.300" }}
      style={{
        transition: "0.32s ease-in-out",
      }}
    >
      <Container maxWidth={false} sx={{ px: { xs: 0, sm: 0, md: 0 } }}>
        <Grid
          container
          m={0}
          px={4}
          sx={{ background: "black", color: "white", gap: 2 }}
          style={{ padding: "10px 3%" }}
        >
          <Grid
            item
            xs={12}
            sm={5.8}
            sx={{
              textAlign: { xs: "center", sm: "left" },
              justifyContent: { xs: "center", sm: "start" },
            }}
            style={{ display: "flex", gap: "24px" }}
          >
            <div className={classes.paired}>
              <a
                href="tel:(516)424-4928"
                style={{ textDecoration: "none", color: " unset" }}
              >
                <span>
                  <CallIcon style={styleForIcon} />
                </span>
                <span
                  style={{
                    top: "-4px",
                    position: "relative",
                    marginLeft: "11px",
                    fontSize: "14px",
                    cursor: "pointer",
                    fontFamily: "sans-serif",
                  }}
                >
                  (516)424-4928
                </span>
              </a>
            </div>
            <div className={classes.paired}>
              <a
                href="mailto:info@prismx.net"
                style={{ textDecoration: "none", color: " unset" }}
              >
                <span>
                  <MailIcon style={styleForIcon} />
                </span>
                <span style={styleForIconTxt}>Info@prismx.us</span>
              </a>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.8}
            ref={searchBoxRef}
            sx={{
              textAlign: { xs: "center", sm: "left" },
              justifyContent: { xs: "center", sm: "end" },
              position: "relative",
            }}
            style={{ display: "flex", gap: "24px" }}
            justifyContent="flex-end"
          >
            {" "}
            <Box
              sx={{
                background: "white",
                padding: "13px",
                position: "absolute",
                zIndex: 9,
                left: "50%",
                top: "44px",
                display: isSearch ? "block" : "none",
                borderRadius: "10px",
              }}
            >
              <Box>
                <input
                  type="text"
                  placeholder="Search..."
                  style={{
                    background: "#fff",
                    fontSize: "16px",
                    border: "1px solid rgb(157 157 157 / 29%)",
                    padding: "14px 20px",
                    outline: "none",
                  }}
                  value={searchInput}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
                <SearchIcon
                  onClick={searchHandler}
                  sx={{
                    color: "black",
                    position: "absolute",
                    top: "25px",
                    right: "22px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
            <div style={{ cursor: "pointer", opacity: "0.5" }}>
              <span style={{ position: "relative" }}>
                <SearchIcon style={styleForIconSearch} onClick={showSearch} />
              </span>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className={`${classes.rightPair}`}
            >
              <span>
                <Link
                  to="/dashboard"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <PersonIcon
                    style={styleForIconUser}
                    className={classes.userIconHover}
                  />
                </Link>
              </span>

              {auth.isLoggedIn === false ? (
                <span style={styleForIconTxt}>
                  <span
                    className={classes.userHover}
                    onClick={() => handleOpenModal("signin")}
                  >
                    {" "}
                    Sign in
                  </span>
                  <span style={{ padding: "0px 5px", opacity: "0.5" }}>or</span>
                  <span
                    className={classes.userHover}
                    onClick={() => handleOpenModal("register")}
                  >
                    Register
                  </span>
                </span>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Modal
        isOpen={isModalOpen}
        UserModelRef={UserModelRef}
        onClose={handleCloseModal}
      >
        {formType === "signin" ? (
          <LoginForm
            handleOpenModal={handleOpenModal}
            setIsModalOpen={setIsModalOpen}
          />
        ) : formType === "register" ? (
          <RegisterForm
            handleOpenModal={handleOpenModal}
            setIsModalOpen={setIsModalOpen}
          />
        ) : formType === "resetPassForm" ? (
          <ResetPasswordForm
            handleOpenModal={handleOpenModal}
            setIsModalOpen={setIsModalOpen}
          />
        ) : null}
      </Modal>
    </Box>
  );
};

export default Topbar;
