import { useContext, useEffect, useState } from "react";
import LoginForm from "./UI/LoginForm";
import RegisterForm from "./UI/RegisterForm";
import ResetPasswordForm from "./UI/ResetPasswordForm";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  styled,
  Tab,
  Tabs,
  Toolbar,
} from "@mui/material";
import { Home, DataObject, Call, Menu } from "@mui/icons-material";
import BookIcon from "@mui/icons-material/Book";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Topbar from "../components/Topbar";
import DrawerItems from "./Drawer";
import header from "../assets/images/header.png";
import header_scrolled from "../assets/images/header_scrolled.png";
import { AuthContext } from "../context/auth-context";
import Modal from "./UI/Modal";

export const drawerWidth = 280;

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "#fff",
  "&:hover": {
    color: "#1c9ac0",
  },
  "&.Mui-selected": {
    color: "#1c9ac0",
  },
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const tab = [
  {
    label: "Home",
    path: "/",
    icon: <Home />,
  },
  {
    label: "About Us",
    path: "/about-us",
    icon: <DataObject />,
  },
  {
    label: "Listings",
    path: "/listings",
    icon: <FormatListNumberedIcon />,
  },
  {
    label: "Blog",
    path: "/blog",
    icon: <BookIcon />,
  },
  {
    label: "Contact",
    path: "/contact",
    icon: <Call />,
  },
];

function Navbar(props) {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(location.pathname || "/");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [isListingsPage, setIsListingsPage] = useState(false);
  const [formType, setFormType] = useState("signin");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserExists(!!user);
  }, []);

  useEffect(() => {
    const match = tab.some((item) => item.path === location.pathname);
    setValue(match ? location.pathname : false);
    setIsListingsPage(
      location.pathname === "/404" ||
        location.pathname.startsWith("/listings/") ||
        location.pathname === "/dashboard" ||
        location.pathname.startsWith("/404/")
    );
  }, [location.pathname]);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setScrolled(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else if (window.innerWidth > 900) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenModal = (type) => {
    setFormType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSignIn = () => {
    if (auth.isLoggedIn) {
      if (auth.userRole == 1) {
        navigate("/dashboard/createuser");
      } else if (auth.userRole == 0) {
        navigate("/dashboard/createlisting");
      }
    } else {
      handleOpenModal("signin");
    }
  };

  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  if (
    location?.pathname === "/dashboard" ||
    /^\/business\//.test(location?.pathname) ||
    isDashboardRoute
  ) {
    return null;
  }

  return (
    <>
      <Box>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "white",
              bgcolor: "#11161f",
            },
          }}
        >
          <DrawerItems tab={tab} handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
      </Box>

      <AppBar
        sx={{
          background: isListingsPage
            ? scrolled
              ? "#11161F"
              : "#11161F"
            : scrolled
            ? "#11161F"
            : "none",
          color: scrolled ? "white" : "none",
          position: "fixed",
          zIndex: 4,
          pb: 0.6,
          transition: "0.32s ease-in-out",
          boxShadow: { md: !scrolled && "none" },
        }}
        position="static"
      >
        {!scrolled && <Topbar />}

        <StyledToolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{
              mr: 2,
              color: "white",
              display: { md: "none" },
            }}
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>

          <Stack
            direction="row"
            display={{ xs: "flex", md: "none" }}
            justifyContent="center"
            pr={2}
            width="100%"
          >
            <Link style={{ textDecoration: "none" }} to="/">
              <IconButton size="large">
                <img
                  src={scrolled ? header_scrolled : header}
                  alt=""
                  style={{ width: "70px", marginRight: "15px" }}
                />
              </IconButton>
            </Link>
          </Stack>

          <Stack
            direction="row"
            display={{ xs: "none", sm: "none", md: "flex" }}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Link style={{ textDecoration: "none" }} to="/">
              <IconButton size="large">
                <img
                  src={scrolled ? header_scrolled : header}
                  alt=""
                  style={{ width: "70px" }}
                />
              </IconButton>
            </Link>

            <Stack direction="row" spacing={1} alignItems="center">
              <Tabs
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "transparent",
                  },
                }}
                sx={{ paddingTop: 1 }}
              >
                {tab.map(({ label, path }, index) => (
                  <StyledTab
                    key={index}
                    label={label}
                    value={path}
                    component={Link}
                    to={path}
                    disableRipple
                    sx={{ color: scrolled ? "white" : "#fff" }}
                  />
                ))}
              </Tabs>
              <Button
                sx={{
                  background: isListingsPage
                    ? scrolled
                      ? "#1c9ac0"
                      : "#1c9ac0"
                    : scrolled
                    ? "#1c9ac0"
                    : "white",
                  color: isListingsPage
                    ? scrolled
                      ? "#fff"
                      : "#fff"
                    : scrolled
                    ? "#fff"
                    : "#1c9ac0",

                  height: "42px",
                  minWidth: "150px",
                  px: 6,
                  borderRadius: 20,
                  "&:hover": {
                    background: "#1c9ac0",
                    color: "white",
                    opacity: 0.9,
                  },
                }}
                onClick={handleSignIn}
              >
                {auth.isLoggedIn ? "Dashboard" : "Sign In"}
              </Button>
            </Stack>
          </Stack>
        </StyledToolbar>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          {formType === "signin" ? (
            <LoginForm
              handleOpenModal={handleOpenModal}
              setIsModalOpen={setIsModalOpen}
            />
          ) : formType === "register" ? (
            <RegisterForm
              handleOpenModal={handleOpenModal}
              setIsModalOpen={setIsModalOpen}
            />
          ) : formType === "resetPassForm" ? (
            <ResetPasswordForm
              handleOpenModal={handleOpenModal}
              setIsModalOpen={setIsModalOpen}
            />
          ) : null}
        </Modal>
      </AppBar>
    </>
  );
}

export default Navbar;
