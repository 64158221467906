import { Box, CardMedia, Grid, Typography } from "@mui/material";
import image from "../../assets/images/AboutUsCard.jpg";
import { useInView } from "react-intersection-observer";

const CompanyAboutUs = ({
  banner,
  title,
  desc,
  aboutUs,
  intro,
  isAPIRunning,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const truncateDesc = (desc, maxLength) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = desc;
    return tempElement.textContent.length > maxLength
      ? tempElement.textContent.substring(0, maxLength) + "..."
      : tempElement.textContent;
  };

  const { ref: aboutUsRef, inView: aboutUsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Grid
      container
      ref={aboutUsRef}
      id="about-us-section"
      sx={{
        backgroundImage:
          "url(https://redvisionexperts.com/wp-content/uploads/2020/10/bg-map-3.png)",
        backgroundPosition: "100% -10%",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
        display: "block",
        justifyContent: "left",
        overflow: "hidden",
        position: "relative",
        px: { lg: 10, md: 5, sm: 10, xs: 4 },
        py: { lg: 9, md: 5, sm: 10, xs: 6 },
        opacity: 0,
        transform: aboutUsInView ? "translateX(0)" : "translateX(-100%)",
        animation: aboutUsInView ? "slideInLeft 1s forwards" : "none",
      }}
      rowSpacing={1}
    >
      <Typography
        variant="h5"
        color="#2d3954"
        sx={{
          fontSize: "32px",
          lineHeight: "42px",
          textAlign: "center",
          fontWeight: 600,
          fontFamily: "poppins",
          mb: "20px",
          mt: { sm: "20px", xs: "20px" },
        }}
      >
        About Us
      </Typography>
      <Typography
        variant="h6"
        color="#72809d"
        sx={{
          fontSize: "16px",
          lineHeight: "22px",
          textAlign: "center",
          fontWeight: 400,
          fontFamily: "poppins",
          marginTop: "10px",
          mb: "30px",
          px: { lg: "150px", md: "auto" },
        }}
      >
        Welcome to {aboutUs}, where we are dedicated to delivering excellence
        and innovation in everything we do. We have built a reputation for
        providing top-quality products and services to our valued customers.
      </Typography>
      <Grid
        item
        xs={12}
        sm={12}
        md={6.5}
        sx={{ position: "relative" }}
        ref={imageRef}
        id="image-section"
        style={{}}
      >
        <CardMedia
          component="img"
          image={isAPIRunning ? `${backendUrl}/${banner}` : `${banner}`}
          alt="ok"
          sx={{
            cursor: "pointer",
            maxWidth: "100%",
            opacity: imageInView ? 1 : 0,
            height: { md: "500px", sm: "400px", xs: "300px" },
            transform: imageInView ? "translateX(0)" : "translateX(-100%)",
            animation: imageInView ? "slideInLeft 1s forwards" : "none",
          }}
        />
        <Grid
          container
          direction="column"
          sx={{
            display: "block",
            position: { md: "absolute", sm: "relative" },
            left: { md: "70%", sm: "0" },
            top: { lg: "11%", md: "17%", sm: "0" },
            width: "100%",
            height: { lg: "400px", md: "auto", sm: "auto", xs: "auto" },
            bgcolor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            px: { lg: 8, md: 5, sm: 3, xs: 2 },
            py: { lg: 7, md: 5, sm: 3, xs: 2 },

            overflow: "hidden",
            opacity: imageInView ? 1 : 0,
            transform: imageInView ? "translateX(0)" : "translateX(100%)",
            animation: imageInView ? "slideInRight 1s forwards" : "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "left",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: { lg: "3rem", sm: "2rem", xs: "1.5rem" },
                fontWeight: "800",
                lineHeight: "1.2",
                textAlign: { md: "left", xs: "center" },
                color: "#11161f",
                mb: { lg: 2, xs: 2 },
                fontFamily: "Barlow",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "grey",
                textAlign: { sm: "left", xs: "center" },
                lineHeight: "1.5",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 6,
                WebkitBoxOrient: "vertical",
                fontSize: { md: "16px", sm: "16px", xs: "12px" },
              }}
            >
              {intro}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* <style>
        {`@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#about-us-section {
  opacity: 0;
  animation: slideInLeft 2s forwards;
}

#image-section {
  opacity: 0;
  animation: slideInRight 2s forwards;
}`}
      </style> */}
    </Grid>
  );
};

export default CompanyAboutUs;
