import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@mui/material";

const CheckboxFilter = ({ label, checked, onChange, name }) => {
  return (
    <FormControlLabel
    sx={{
      color: "#2d3954",
      display: "block",
    }}
    control={
      <Checkbox
        size="small"
        checked={checked}
        onChange={onChange}
        name={name}
      />
    }
    label={<span style={{ fontSize: "13px" }}>{label}</span>} // Adjust the fontSize as needed
  />
  );
};

export default CheckboxFilter;
