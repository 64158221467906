import React from "react";
import { Grid, Typography, Box, Pagination } from "@mui/material";
import PropertyItemCard from "../Listing/PropertyCardItem";

const  PropertyCard = ({
  handleDeleteItem,
  items,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  return (
    <>
      <Grid
        container
        item
        sx={{
          background: "",
          textAlign: "center",
          display: "flex",
          justifyContent: items.length === 0 ? "center" : "flex-start",
          gap: { xs: "20px", lg: "45px" },
        }}
        pt={{ xs: 4, md: 0 }}
        pb={{ xs: 8, md: 12 }}
        pr={{ xs: 2, sm: "23px" }}
        pl={{ xs: 0, sm: "23px" }}
      >
        {items.length === 0 ? (
          <Typography variant="h6" sx={{ mt: 4, color: "#525252" }}>
            No Result Found
          </Typography>
        ) : (
          items.map((item, index) => (
            <PropertyItemCard
              key={index}
              item={item}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleDeleteItem={handleDeleteItem}
            />
          ))
        )}
      </Grid>
      {totalPages >= 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "60px",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
};

export default PropertyCard;
