import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
  CircularProgress,
  IconButton,
} from "@mui/material";
import bgShape from "../../assets/images/bg-shape.png";
import DefaultImg from "../../assets/images/placeholder.png";
import SectionIntro from "../Home/SectionInto";
import ReplayIcon from "@mui/icons-material/Replay";
import { Link } from "react-router-dom";
import axios from "axios";

function truncateTitle(title) {
  return title.length > 30 ? title.substring(0, 30) + "..." : title;
}

function truncateDesc(desc) {
  return desc.length > 70 ? desc.substring(0, 70) + "..." : desc;
}

const FeaturedListing = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(`${backendUrl}/api/places`);
      setData(response.data.places);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: `url(${bgShape})`,
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        marginTop: "7rem",
        marginBottom: "7rem",
        px: { md: 0, sm: 0, xs: 3 },
      }}
    >
      <Box pt={5}>
        <SectionIntro
          title="EXCLUSIVE RECOMMENDATIONS"
          subtitle="Featured Listings"
          description={
            loading
              ? ""
              : error
              ? ""
              : "Browse through our featured business listings, a selection of businesses that are loved by locals and recommended by experts."
          }
        />
      </Box>
      <Container maxWidth="lg">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <Typography fontSize={"16px"} color="error" gutterBottom>
              Error fetching featured listing, Please try again.
            </Typography>
            <IconButton aria-label="delete" size="medium">
              <ReplayIcon fontSize="inherit" onClick={fetchData} />
            </IconButton>
          </Box>
        ) : data.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <Typography fontSize={"25px"} color="black" gutterBottom>
              No listings found.
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container>
            {Array.isArray(data) &&
              data.slice(0, 3).map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ padding: { xs: "5px", sm: "9px", md: "0px" } }}
                >
                  <Link
                    to={`/listings/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        height: { md: "550px", sm: "550px", xs: "420px" },
                        pb: 0,
                        cursor: "pointer",
                        position: "relative",
                        transition: "transform 0.3s",
                        borderRadius: "2.5em",
                        boxShadow:
                          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                        "&:hover": {
                          "& .MuiCardMedia-root": {
                            transform: "scale(1.1)",
                          },
                          "& Button": {
                            color: "white",
                            opacity: 0.9,
                          },
                          "& h6": {
                            opacity: "1",
                            top: "-45px",
                          },
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          pb: 1,
                          width: "100%",
                          position: "relative",
                          padding: "20px",
                          mt: "-20px",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "2.5em",
                            overflow: "hidden",
                            m: "15px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={
                              item.image
                                ? `${backendUrl}/${item.image}`
                                :  DefaultImg
                            }
                            sx={{
                              height: { md: "340px", sm: "340px", xs: "200px" },
                              objectFit: "cover",
                              transition: "all 0.3s linear 0s",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = DefaultImg;
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "500",
                              letterSpacing: "1px",
                              lineHeight: "26px",
                              fontFamily: "poppins",
                              fontSize: "14px",
                              color: "white",
                              position: "absolute",
                              top: "-20px",
                              opacity: "0",
                              transition: " all 0.3s ease",
                              mt: "-10px",
                            }}
                          >
                            View Business
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "1px",
                            lineHeight: "26px",
                            pt: "10px",
                            textAlign: "center",
                            marginTop: "30px",
                            fontFamily: "poppins",
                            fontSize: "18px",
                            marginBottom: "5px",
                            px: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {truncateTitle(item.title)}
                        </Typography>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{
                            fontWeight: "500",
                            letterSpacing: "1px",
                            lineHeight: "23px",
                            textAlign: "center",
                            marginTop: "10px",
                            fontFamily: "poppins",
                            fontSize: "15px",
                            marginBottom: "10px",
                            color: "#4a4a4a",
                            px: "10px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: truncateDesc(item.desc),
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default FeaturedListing;