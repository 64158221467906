import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Container,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import PropertyCard from "../components/Listing/PropertyCard";
import Searchbar from "../components/Listing/Searchbar";
import SideFilter from "../components/Listing/SideFilter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import PageHeader from "../components/UI/PageHeader";
import { AuthContext } from "../context/auth-context";
import listingPage from "../../src/assets/images/lisitngPage.jpg";
import { useLocation } from "react-router-dom";

const Listings = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const paramCategory = location?.state?.categoryData;

  const [region, setRegion] = useState("");
  const auth = useContext(AuthContext);
  const [searchKeyword, setSearchKeyword] = useState(
    location?.state?.searchInput
  );
  const [propertyType, setPropertyType] = useState(
    location?.state?.categoryData
  );

  const [filteredData, setFilteredData] = useState([]);
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [accNTaxService, setAccNTaxService] = useState([]);
  const [area, setArea] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const itemsPerPage = 12;
  const [totalPlaces, setTotalPlaces] = useState(null);

  const handleDeleteItem = (id) => {
    fetch(`${backendUrl}/api/places/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const updatedData = items.filter((item) => item.id !== id);
        setItems(updatedData);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        custom: 1379,
      },
    },
    components: {
      MuiContainer: {
        variants: [
          {
            props: { maxWidth: "custom" },
            style: {
              maxWidth: 1300,
            },
          },
        ],
      },
    },
  });

  const fetchData = async (page) => {
    try {
      setLoading(true);
      setError(false);
      const searchParams = {};
      if (category) {
        searchParams.category = category;
      }

      let url;
      url = `${backendUrl}/api/places/?page=${page}&limit=${itemsPerPage}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      // const response = await axios.get(`${backendUrl}/api/places`, {
      //   params: {
      //     page: page,
      //     limit: itemsPerPage,
      //   },
      // });

      const data = await response.json();
      setItems(data.places);
      setFilteredItems(data.places);
      setTotalPages(data.totalPages);
      setTotalPlaces(data.totalPlaces);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const categoryArray = filteredItems.map((data) => ({
    label: data.category,
    value: data.category,
  }));

  function makeArrayUnique(arr) {
    const seen = new Set();
    return arr.filter((item) => {
      const duplicate = seen.has(item.label);
      seen.add(item.label);
      return !duplicate;
    });
  }

  const uniqueArray = makeArrayUnique(categoryArray);

  const getAllPlaces = async () => {
    const res = await axios.get(`${backendUrl}/api/places`);
    setItems(res.data.places);
  };

  useEffect(() => {
    getAllPlaces();
  }, []);

  const clearFilter = async () => {
    setSearchKeyword("");
    setCategory("");
    setPriceRange("");
    setArea("");
    setRegion("");
    setCity("");
    setAccNTaxService([]);
    try {
      setLoading(true);
      const res = await axios.get(`${backendUrl}/api/places`);
      setItems(res.data.places);
    } catch (error) {
      console.error(
        "Error clearing filters and fetching initial items:",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  return (
    <ThemeProvider theme={theme}>
      <PageHeader image={listingPage} page="All Listings" />

      <Box
        component="section"
        style={{}}
        pl={{ xs: 2, sm: 4, md: 0 }}
        pt={{ xs: 6, sm: 6, md: 6.5 }}
        color="grey.300"
        bgcolor="white"
      >
        <Container maxWidth="custom" style={{ paddingLeft: "0px" }}>
          <Typography
            variant="h5"
            color="#2d3954"
            style={{
              fontSize: "32px",
              lineHeight: "42px",
              textAlign: "center",
              fontWeight: 600,
              fontFamily: "poppins",
            }}
            ref={gridRef}
          >
            Explore Our Directory
          </Typography>
          <Typography
            variant="h6"
            color="#72809d"
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              textAlign: "center",
              fontWeight: 400,
              fontFamily: "poppins",
              marginTop: "10px",
            }}
          >
            Check out our comprehensive directory to find a wide range of
            valuable resources and information.
          </Typography>

          <Grid container spacing={4} mt={2} m={0} pb={6} pt={3}>
            <Grid
              item
              xs={11.4}
              sm={11.2}
              lg={3}
              md={3}
              sx={{
                pr: { xs: 2, md: 0, lg: 5 },
                position: { xs: "relative", md: "sticky" },
                top: { xs: "0px", md: "150px" },
              }}
              style={{
                paddingLeft: "0px",
                alignSelf: "flex-start",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: { sm: "30px", md: "15px", lg: "22px" },
                  borderRadius: "6px",
                  boxShadow:
                    " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <SideFilter
                  setAccNTaxService={setAccNTaxService}
                  accNTaxService={accNTaxService}
                  area={area}
                  setArea={setArea}
                  setItems={setItems}
                  items={items}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={11.7}
              sm={11.2}
              md={9}
              style={{
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Searchbar
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                data={filteredItems}
                category={category}
                setCategory={setCategory}
                setCity={setCity}
                city={city}
                item={items}
                setItems={setItems}
                priceRange={priceRange}
                categoryArray={uniqueArray}
                setPriceRange={setPriceRange}
                accNTaxService={accNTaxService}
                setAccNTaxService={setAccNTaxService}
                area={area}
                setArea={setArea}
                loading={loading}
                setLoading={setLoading}
                error={error}
                setError={setError}
                setRegion={setRegion}
                region={region}
                paramCategory={paramCategory}
                clearFilter={clearFilter}
                setTotalPages={setTotalPages}
              />
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Typography fontSize={"16px"} color="error" gutterBottom>
                    Error fetching Listings, Please try again.
                  </Typography>
                  <IconButton
                    aria-label="retry"
                    size="medium"
                    onClick={() => fetchData(currentPage)}
                  >
                    <ReplayIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              ) : items.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Typography fontSize={"25px"} color="black">
                    No listings found.
                  </Typography>
                </Box>
              ) : (
                <PropertyCard
                  items={items}
                  handleDeleteItem={handleDeleteItem}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setItems={setItems}
                  totalPages={totalPages}
                  totalPlaces={totalPlaces}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Listings;
