import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import CallIcon from "@mui/icons-material/Call";

const useStyles = makeStyles((theme) => ({
  userHover: {
    "&:hover": {
      color: "#1c9ac0",
      opacity: "1",
    },
    opacity: "0.5",
  },
  userIconHover: {
    "&:hover": {
      opacity: "1",
    },
    opacity: "0.5",
  },
  paired: {
    "&:hover": {
      opacity: "1",
    },
    opacity: "0.5",
    cursor: "pointer",
  },
  rightPair: {
    position: "relative",
    top: "2px",
  },
}));

const styleForIcon = {
  fontSize: "19px",
};

const styleForIconTxt = {
  top: "-4px",
  position: "relative",
  marginLeft: "11px",
  fontSize: "14px",
  cursor: "pointer",
  fontFamily: "sans-serif",
};

const CompanyTopbar = ({ isLoggedIn, phone, website, formattedNumber }) => {
  const classes = useStyles();
  const location = useLocation();
  const searchBoxRef = useRef(null);
  const [isSearch, setIsSearch] = useState(false);
  const shouldHide = website === "https://www.example.com/";

  useEffect(() => {
    setIsSearch(false);
  }, [location]);

  return (
    <Box
      sx={{ bgcolor: "grey.900", color: "grey.300", position: "sticky" }}
      style={{
        transition: "0.32s ease-in-out",
        height: "auto",
      }}
    >
      <Container maxWidth={false} sx={{ px: { xs: 0, sm: 0, md: 0 } }}>
        <Grid
          container
          m={0}
          sx={{
            background: "black",
            color: "white",
            gap: 2,
            px: { sm: 4, xs: 0 },
            pt: "10px",
            pb: { sm: "8px", xs: 0 },
          }}
        >
          <Grid
            item
            xs={12}
            sm={5.8}
            sx={{
              textAlign: { xs: "center", sm: "left" },
              justifyContent: { xs: "center", sm: "start" },
              display: "flex",
              columnGap: "24px",
              flexWrap: "wrap",
            }}
          >
            <a
              href={`tel:${phone}`}
              style={{ textDecoration: "none", color: " unset" }}
            >
              <div className={classes.paired}>
                <span>
                  <CallIcon style={styleForIcon} />
                </span>
                <span
                  style={{
                    top: "-4px",
                    position: "relative",
                    marginLeft: "11px",
                    fontSize: "14px",
                    cursor: "cursor",
                    fontFamily: "sans-serif",
                  }}
                >
                  {formattedNumber}
                </span>
              </div>
            </a>
            <div className={classes.paired}>
              {!shouldHide && (
                <a
                  href={website}
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <span>
                    <LanguageIcon style={styleForIcon} />
                  </span>
                  <span style={styleForIconTxt}>{website}</span>
                </a>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.8}
            ref={searchBoxRef}
            sx={{
              textAlign: { xs: "center", sm: "left" },
              justifyContent: { xs: "center", sm: "end" },
              position: "relative",
            }}
            style={{ display: "flex", gap: "24px" }}
            justifyContent="flex-end"
          >
            {" "}
            <Box
              sx={{
                background: "white",
                padding: "13px",
                position: "absolute",
                zIndex: 9,
                left: "50%",
                top: "44px",
                display: isSearch ? "block" : "none",
                borderRadius: "10px",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyTopbar;
