import { useState, useEffect } from "react";

const useFormatData = (data) => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (data) {
      // Use a regular expression to split based on hyphens surrounded by spaces or other list markers
      const formatted = data
        .split(/\s*[-•]\s*/)
        .filter((item) => item.trim() !== "")
        .map((item) => item.trim());
      setFormattedData(formatted);
    }
  }, [data]);

  return formattedData;
};

export default useFormatData;
