import {
  Login,
  Input,
  Menu,
  Message,
  Logout,
  Dashboard,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import header_scrolled from "../assets/images/header_scrolled.png";
import { useContext } from "react";
import { AuthContext } from "../context/auth-context";
import Close from "@mui/icons-material/Close";

const DrawerItems = ({ tab, handleDrawerToggle }) => {
  const [userExists, setUserExists] = useState(false);
  const auth = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  }, []);

  const drawerTab = [
    {
      label: "Login",
      path: "/login",
      icon: <Login />,
    },
    {
      label: "Register",
      path: "/signup",
      icon: <Input />,
    },
  ];

  const logoutTab = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <Dashboard />,
    },
    {
      label: "Logout",
      path: "#",
      icon: <Logout />,
      onClick: () => {
        localStorage.clear(); 
        auth.logout(); 
        handleDrawerToggle(); 
      },
    },
  ];

  if (userExists) {
    drawerTab.pop();
  }

  const finalTab = auth.isLoggedIn
    ? tab.concat(logoutTab)
    : tab.concat(drawerTab);

  const handleClickClose = () => {
    handleDrawerToggle();
  };

  return (
    <>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <Link style={{ textDecoration: "none" }} to="/">
              <IconButton size="large">
                <img
                  src={header_scrolled}
                  alt=""
                  style={{ width: "30px", marginTop: "4px" }}
                />
              </IconButton>
            </Link>
          </Stack>
          <Stack mt={0.6} mr={{ xs: -2, sm: -3 }} justifySelf="flex-end">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <Close onClick={handleClickClose} />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
      <Divider color="gainsboro" />
      <List sx={{ py: 0, mt: 2 }}>
        {finalTab.map((data, index) => {
          const isActive = location.pathname === data.path;

          return (
            <ListItem
              disablePadding
              key={index}
              component={data.path.startsWith("http") ? "a" : Link}
              to={data.path.startsWith("http") ? undefined : data.path}
              href={data.path.startsWith("http") ? data.path : undefined}
              target={data.path.startsWith("http") ? "_blank" : undefined}
              rel={
                data.path.startsWith("http") ? "noopener noreferrer" : undefined
              }
              sx={{
                color: isActive ? "#fff" : "white",
                backgroundColor: isActive ? "#1c9ac0" : "inherit",
              }}
              onClick={data.onClick ? data.onClick : handleDrawerToggle}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "white" }}>{data.icon}</ListItemIcon>
                <ListItemText
                  sx={{ ml: -2 }}
                  primaryTypographyProps={{ fontSize: 13, fontWeight: "bold" }}
                  primary={data.label}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default DrawerItems;