import React, { createContext, useState } from "react";

export const PendingCounterContext = createContext();

export const PendingCounterProvider = ({ children }) => {
  const [counter, setCounter] = useState([]);

  return (
    <PendingCounterContext.Provider value={{ counter, setCounter }}>
      {children}
    </PendingCounterContext.Provider>
  );
};
