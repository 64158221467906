import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import {
  Container,
  Grid,
  Typography,
  Box,
  CardMedia,
  Paper,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RoomIcon from "@mui/icons-material/Room";
import PublicIcon from "@mui/icons-material/Public";
import CallIcon from "@mui/icons-material/Call";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import Review from "../components/LisitngDetail.jsx/Review";
import RealtedBusinessCard from "../components/LisitngDetail.jsx/RealtedBusinessCard";
import ReplayIcon from "@mui/icons-material/Replay";
import DummyListing from "../components/Data/DummyListing";
import { AuthContext } from "../context/auth-context";
import NotFound from "../pages/NotFound";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      custom: 1350,
    },
  },
  components: {
    MuiContainer: {
      variants: [
        {
          props: { maxWidth: "custom" },
          style: {
            maxWidth: 1300,
          },
        },
      ],
    },
  },
});
const useStyles = makeStyles((theme) => ({
  cardIcon: {
    position: "relative",
    top: "7px",
  },
}));

const ListingDetails = () => {
  const auth = useContext(AuthContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const classes = useStyles();
  const { pid } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [listing, setListing] = useState("");
  const [isAPIRunning, setIsAPIRunning] = useState(false);
  const [sendRole, setSendRole] = useState(null);
  const [isRoleSet, setIsRoleSet] = useState(false);

  useEffect(() => {
    const storedUserRole = localStorage.getItem("userRole");
    console.log("storedUserRole", storedUserRole);
    if (storedUserRole) {
      setSendRole(parseInt(storedUserRole, 10));
      setIsRoleSet(true);
    } else {
      setIsRoleSet(true); // Set to true so that fetchData can still run with default URL
    }
  }, []);

  useEffect(() => {
    if (pid && isRoleSet) {
      fetchData(pid);
    }
  }, [pid, type, isRoleSet]);

  const fetchData = async (pid) => {
    try {
      setLoading(true);
      setError(false);

      // Construct the URL based on conditions
      const url =
        type === "listing" || type === null || type === undefined
          ? `${backendUrl}/api/places/${pid}`
          : `${backendUrl}/api/pendings/${pid}/${sendRole}`;

      console.log("Constructed URL:", url);
      console.log("type:", type);
      console.log("sendRole:", sendRole);

      const response = await axios.get(url);
      setListing(response.data.place);
      setIsAPIRunning(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
      setListing(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !listing) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography fontSize={"16px"} color="error" gutterBottom>
          No Listing Found
        </Typography>
        <IconButton aria-label="retry" size="medium" onClick={fetchData}>
          <ReplayIcon fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }

  const shouldHide = listing.website === "https://www.example.com/";

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          component="section"
          style={{ fontFamily: "poppins" }}
          pl={{ xs: 0, sm: 0, md: 0 }}
          pt={{ xs: 26, sm: 24, md: 23 }}
          pb={{ xs: 5, sm: 8, md: 8 }}
          color="black"
          bgcolor="white"
        >
          <Container maxWidth="custom">
            <Grid spacing={2} container>
              <Grid item xs={12} lg={6}>
                <CardMedia
                  component="img"
                  height="357"
                  image={
                    isAPIRunning
                      ? `${backendUrl}/${listing.image}`
                      : listing.image
                  }
                  alt={listing.title}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid spacing={2} container>
                  <Grid item xs={6} lg={6}>
                    <CardMedia
                      component="img"
                      height="170"
                      image={
                        isAPIRunning
                          ? `${backendUrl}/${listing.image1}`
                          : listing.image1
                      }
                      alt={listing.title}
                    />{" "}
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <CardMedia
                      component="img"
                      height="170"
                      image={
                        isAPIRunning
                          ? `${backendUrl}/${listing.image2}`
                          : listing.image2
                      }
                      alt={listing.title}
                    />{" "}
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <CardMedia
                      component="img"
                      height="170"
                      image={
                        isAPIRunning
                          ? `${backendUrl}/${listing.image3}`
                          : listing.image3
                      }
                      alt={listing.title}
                    />{" "}
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <CardMedia
                      component="img"
                      height="170"
                      image={
                        isAPIRunning
                          ? `${backendUrl}/${listing.image4}`
                          : listing.image4
                      }
                      alt={listing.title}
                    />{" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={8} lg={8}>
                <Box component="section">
                  <Box component="section" gap={2} sx={{ display: "none" }}>
                    <span
                      style={{
                        background: "rgb(9 28 62 / 10%)",
                        padding: "6px 14px",
                        borderRadius: "20px",
                        fontSize: "15px",
                      }}
                    >
                      1 Beds
                    </span>{" "}
                    <span
                      style={{
                        background: "rgb(9 28 62 / 10%)",
                        padding: "6px 14px",
                        borderRadius: "20px",
                        fontSize: "15px",
                      }}
                    >
                      2 Bath
                    </span>
                    <span
                      style={{
                        background: "rgb(9 28 62 / 10%)",
                        padding: "6px 14px",
                        borderRadius: "20px",
                        fontSize: "15px",
                      }}
                    >
                      714 Sqft
                    </span>
                  </Box>
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ marginTop: "20px" }}
                    style={{
                      fontSize: "16px",
                      lineHeight: "42px",
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "poppins",
                      marginTop: "10px",
                      color: "#2d3954",
                    }}
                  >
                    Category:<span> {listing.category} </span>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="black"
                    style={{
                      fontSize: "25px",
                      lineHeight: "42px",
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "poppins",
                      marginTop: "10px",
                      color: "#2d3954",
                    }}
                  >
                    {listing.title}
                    <Link
                      style={{ textDecoration: "none", color: "unset" }}
                      to={`/business/${listing.slug}?type=${type}`}
                    >
                      <OpenInNew
                        sx={{
                          marginLeft: "3px",
                          fontSize: "15px",
                          mb: "10px",
                          "&:hover": { color: "#007BFF" },
                        }}
                      />
                    </Link>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "left" }}
                  >
                    <span>
                      <RoomIcon
                        style={{
                          fontSize: "19px",
                          position: "relative",
                          top: "4px",
                        }}
                      />
                    </span>{" "}
                    {listing.address}{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ marginTop: "20px" }}
                    style={{
                      fontSize: "16px",
                      lineHeight: "42px",
                      textAlign: "left",
                      fontWeight: 600,
                      fontFamily: "poppins",
                      marginTop: "10px",
                      color: "#2d3954",
                    }}
                    dangerouslySetInnerHTML={{ __html: listing.desc }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    color="black"
                    sx={{ marginTop: "20px", paddingRight: "25px" }}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                      textAlign: "left",
                      fontWeight: 400,
                      fontFamily: "poppins",
                      marginTop: "10px",
                      color: "#72809d",
                    }}
                  >
                    {listing.aboutBusiness}
                  </Typography>
                </Box>
                <RealtedBusinessCard
                  listingforCategory={listing}
                  listing={listing}
                />
                <Box component="section" sx={{ pr: { md: 3 } }}>
                  <Review />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                style={{
                  position: "sticky",
                  top: "150px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    marginTop: "20px",
                    padding: "26px 16px",
                    boxShadow:
                      " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "11px", md: "13px" },
                      letterSpacing: "1px",
                      lineHeight: "2.4rem",
                      fontFamily: "poppins",
                      color: "#2d3954",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1c9ac0",
                      },
                    }}
                  >
                    <a
                      href={`tel:${listing.phone}`}
                      style={{ textDecoration: "none", color: " unset" }}
                    >
                      <span>
                        <CallIcon
                          sx={{
                            color: "#1c9ac0",
                          }}
                          className={classes.cardIcon}
                        />
                      </span>{" "}
                      <span>{listing.phone}</span>
                    </a>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "11px", md: "13px" },
                      letterSpacing: "1px",
                      lineHeight: "2.4rem",
                      fontFamily: "poppins",
                      color: "#2d3954",
                      cursor: "default",
                      display: "flex",
                    }}
                  >
                    <div>
                      <RoomIcon
                        sx={{
                          color: "#1c9ac0",
                        }}
                        className={classes.cardIcon}
                      />
                    </div>{" "}
                    <div style={{ paddingLeft: "5px" }}>{listing.address}</div>
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "11px", md: "13px" },
                      letterSpacing: "1px",
                      lineHeight: "2.4rem",
                      fontFamily: "poppins",
                      color: "#2d3954",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1c9ac0",
                      },
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={listing.website}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      <span>
                        <PublicIcon
                          sx={{
                            color: "#1c9ac0",
                          }}
                          className={classes.cardIcon}
                        />
                      </span>
                      {!shouldHide ? (
                        <span>{listing.website}</span>
                      ) : (
                        <span style={{ paddingLeft: "10px" }}>-</span>
                      )}
                    </a>
                  </Typography>
                </Paper>
                <Paper
                  elevation={3}
                  sx={{
                    marginTop: "20px",
                    padding: "15px 0px 0px",
                    boxShadow:
                      " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "11px", md: "16px" },
                      paddingLeft: "20px",
                      paddingBottom: "12px",
                      letterSpacing: "1px",
                      lineHeight: "2.4rem",
                      fontFamily: "poppins",
                      color: "#2d3954",
                      cursor: "pointer",
                      "&:hover": {},
                    }}
                  >
                    <a
                      target="blank"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      <span>
                        <DirectionsIcon
                          sx={{
                            color: "#1c9ac0",
                          }}
                          className={classes.cardIcon}
                        />
                      </span>{" "}
                      <span>Direction</span>
                    </a>
                  </Typography>
                  <iframe
                    src={listing.mapUrl}
                    width="100%"
                    height="250px"
                    style={{ border: 0, position: "relative", top: "6px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ListingDetails;
