import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Chip } from "@mui/material";
import Blogdata from "./BlogData";
import { Link, useNavigate } from "react-router-dom";

const BlogDetailSideBar = () => {
  const recentPosts = Blogdata.slice().reverse().slice(0, 5);

  const categoryCount = {};
  Blogdata.forEach((blog) => {
    const category = blog.category || "Uncategorized";
    if (categoryCount[category]) {
      categoryCount[category]++;
    } else {
      categoryCount[category] = 1;
    }
  });

  const tagSet = new Set();
  Blogdata.forEach((blog) => {
    blog.tags.forEach((tag) => tagSet.add(tag));
  });

  const navigate = useNavigate();

  const handleTagClick = (tag) => {
    navigate("/blog", { state: { selectedTag: tag } });
  };

  const handleCategoryClick = (category) => {
    navigate("/blog", { state: { selectedCategory: category } });
  };

  return (
    <Grid
      sx={{
        display: "block",
        pr:{md:0,sm:0,xs:2},
        mr: { md: "140px", sm: "20px", xs: "0px" },
        mt: { xs: -6, sm: "40px" },
        ml: { xs: "6vw", sm: 0 },
        width: "auto",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          minWidth: 290,
          px: { xs: 0, md: "30px" },
          overflow: "hidden",
          mb: "30px",
          bgcolor: "#fff",
          position: "relative",
          border: "none",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",

          width: "auto",
        }}
      >
        <CardContent>
          <Typography
            variant="h2"
            component="div"
            sx={{
              mt: "15px",
              mb: "20px",
              fontSize: "2rem",
              fontWeight: "600",
              lineHeight: "1.3",
              color: "#161c26",
            }}
          >
            Category
          </Typography>
          {Object.keys(categoryCount).map((category, index) => (
            <Grid
              key={index}
              sx={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => handleCategoryClick(category)}
            >
              <Typography
                variant="body2"
                key={index}
                sx={{
                  my: "10px",
                  lineHeight: "1.5",
                  "&:hover": {
                    color: "#1c9ac0",
                    cursor: "pointer",
                  },
                }}
              >
                {category}
              </Typography>
              <Typography
                variant="body2"
                key={index}
                sx={{
                  my: "10px",
                  lineHeight: "1.5",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                {" "}
                ({categoryCount[category]})
              </Typography>
            </Grid>
          ))}
        </CardContent>
      </Card>

      <Card
        sx={{
          minWidth: 300,
          px: { xs: 0, md: "30px" },
          overflow: "hidden",
          overflow: "hidden",
          mb: "30px",
          bgcolor: "#fff",
          position: "relative",
          border: "none",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          width: "auto",
        }}
      >
        <CardContent>
          <Typography
            variant="h2"
            component="div"
            sx={{
              mt: "15px",
              mb: "20px",
              fontSize: "1.8rem",
              fontWeight: "600",
              lineHeight: "1.3",
              color: "#161c26",
            }}
          >
            Tags
          </Typography>
          {[...tagSet].map((tag, index) => (
            <Chip
              label={tag}
              key={index}
              onClick={() => handleTagClick(tag)}
              sx={{
                m: "5px",
                "&:hover": {
                  bgcolor: "#1c9ac0",
                  color: "#ffffff",
                  cursor: "pointer",
                },
              }}
            />
          ))}
        </CardContent>
      </Card>

      <Card
        sx={{
          minWidth: 250,
          px: { xs: 0, md: "30px" },
          overflow: "hidden",
          mb: "30px",
          bgcolor: "#fff",
          position: "relative",
          border: "none",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",

          width: "auto",
        }}
      >
        <CardContent>
          <Typography
            variant="h2"
            component="div"
            sx={{
              mt: "15px",
              mb: "20px",
              fontSize: "2rem",
              fontWeight: "600",
              lineHeight: "1.3",
              color: "#161c26",
            }}
          >
            Recent Posts
          </Typography>
          {recentPosts.map((item, index) => (
            <Link
              to={`/blogdetail/${item.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="body2"
                key={index}
                sx={{
                  mt: "20px",
                  lineHeight: "1.5",
                  "&:hover": {
                    color: "#1c9ac0",
                    cursor: "pointer",
                  },
                }}
              >
                {item.label}
              </Typography>
            </Link>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BlogDetailSideBar;
