import React from "react";
import { Box, Button } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Modal = ({ isOpen, onClose, children, UserModelRef }) => {
  if (!isOpen) return null;
  return (
    <>
      <style>{`
        @keyframes slideIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes slideInContent {
          from {
            transform: translateY(-50px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <Box
        sx={{
          position: "fixed",
          top: "0%",
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          animation: "slideIn 0.5s ease",
        }}
      >
        <Box
          ref={UserModelRef}
          sx={{
            width: "500px",
            backgroundColor: "#fff",
            padding: "30px 55px",
            borderRadius: "8px",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
            position: "relative",
            transform: "translateY(-50px)",
            opacity: 0,
            animation: "slideInContent 0.5s ease forwards",
          }}
        >
          <Button
            onClick={onClose}
            sx={{
              position: "absolute",
              borderRadius:"50%",
              top: "-8px",
              right: "-12px",
              minWidth: "30px",
              height: "30px",
              background: "#1c9ac0",
              color: "#fff",
              padding: "0px",
              "&:hover": {
                background: "#1c9ac0",
              },
            }}
          >
            <CloseRoundedIcon sx={{ fontSize: "18px" }} />
          </Button>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Modal;
