const CompanyContactData = [
  {
    email: "Info@prismx.us",
    phone: "(516)424-4928",
    OfficeLocation: "165 DENROSE DRIVE APT 6 BUFFALO NY 14228",
    tiktok: "https://www.tiktok.com/@prismx786",
    instagram: "https://www.instagram.com/prismx_official_/ ",
    facebook: "https://www.facebook.com/officialprismx",
    pinterest: "https://www.pinterest.com/prismx786/"
  },
];
export default CompanyContactData;


