import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [userRole, setRole] = useState(0);
  const [userEmail, setUserEmail] = useState("");

  const login = useCallback((uid, role, tokens, email, expirationDate) => {
    setToken(tokens);
    setUserId(uid);
    setRole(role);
    setUserEmail(email);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem("userId", uid);
    localStorage.setItem("userRole", role);
    localStorage.setItem("userEmail", email);
    localStorage.setItem("token", tokens);
    localStorage.setItem("expiration", tokenExpirationDate.toISOString());
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userName");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const uuid = localStorage.getItem("userId");
    const role = localStorage.getItem("userRole");
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("token");
    const expiry = localStorage.getItem("expiration");

    if (token && new Date(expiry) > new Date()) {
      login(uuid, role, token, email, new Date(expiry));
    }
  }, [login]);

  return { token, login, logout, userId, userRole, userEmail };
};
