import React from "react";
import { Typography } from "@mui/material";

const TitleHeading = ({ text, ...rest }) => {
  return (
    <Typography
      variant="h5"
      style={{
        fontSize: "25px",
        lineHeight: "42px",
        textAlign: "left",
        fontWeight: 600,
        fontFamily: "Poppins",
        marginTop: "10px",
        color: "#2d3954",
      }}
      sx={{
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "80px",
          height: "3px",
          backgroundColor: "#1c9ac0",
        },
      }}
      {...rest}
    >
      {text}
    </Typography>
  );
};

export default TitleHeading;
