import React from "react";
import { Grid, Paper, Box, Typography, Container } from "@mui/material";
import bgMap2 from ".././../assets/images/bg-map-2.png";
import SectionIntro from "../Home/SectionInto";
import TestimonialSlider from "../Home/TestimonialSlider";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Team = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "#f0f3f6",
        backgroundImage: `url(${bgMap2})`,
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        mt: { sm: "100px", xs: "80" },
        pt: "150px",
        position: "relative",
      }}
    >
      <Grid
        sx={{
          bgcolor: "#fff",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mx: { md: 20, sm: 5, xs: 0 },
          width: { lg: "80%", md: "70%", sm: "90%", xs: "auto" },
          borderTop: "10px solid #1c9ac0",
          borderRadius: "5px",
          py: 8,
          height: "auto",
          position: { md: "absolute", xs: "relative" },
          top: "-80px",
        }}
        container
        ref={ref}
      >
        <Grid
          item
          xs={12}
          sm={3}
          lg={3}
          sx={{
            borderRight: {
              xs: "0px solid #dee2e8",
              sm: "1px solid #dee2e8",
            },
            borderBottom: {
              xs: "1px solid #dee2e8",
              sm: "0px solid #dee2e8",
            },
            mt: "10px",
            pb: { xs: "3rem", sm: "0px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "black",
              fontSize: { xs: "30px", sm: "40px", md: "60px" },
              fontWeight: 500,
              lineHeight: "72px",
            }}
          >
            {inView && <CountUp end={8705} duration={2} />}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "rgb(121, 127, 137)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "29px",
            }}
          >
            Total Listings
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2.9}
          lg={2.6}
          sx={{
            borderRight: {
              xs: "0px solid #dee2e8",
              sm: "1px solid #dee2e8",
            },
            borderBottom: {
              xs: "1px solid #dee2e8",
              sm: "0px solid #dee2e8",
            },
            mt: "10px",
            pb: { xs: "3rem", sm: "0px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "black",
              fontSize: { xs: "30px", sm: "40px", md: "60px" },
              fontWeight: 500,
              lineHeight: "72px",
            }}
          >
            {inView && <CountUp end={480} duration={2} />}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "rgb(121, 127, 137)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "29px",
            }}
          >
            Company Staff
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2.9}
          lg={2.6}
          sx={{
            borderRight: {
              xs: "0px solid #dee2e8",
              sm: "1px solid #dee2e8",
            },
            borderBottom: {
              xs: "1px solid #dee2e8",
              sm: "0px solid #dee2e8",
            },
            mt: "10px",
            pb: { xs: "3rem", sm: "0px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "black",
              fontSize: { xs: "30px", sm: "40px", md: "60px" },
              fontWeight: 500,
              lineHeight: "72px",
            }}
          >
            {inView && <CountUp end={6260} duration={2} />}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "rgb(121, 127, 137)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "29px",
            }}
          >
            Places in the World
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2.9}
          lg={2.6}
          sx={{
            mt: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "black",
              fontSize: { xs: "30px", sm: "40px", md: "60px" },
              fontWeight: 500,
              lineHeight: "72px",
            }}
          >
            {inView && <CountUp end={9774} duration={2} />}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Barlow",
              color: "rgb(121, 127, 137)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "29px",
            }}
          >
            Happy People
          </Typography>
        </Grid>
      </Grid>
      <Box py={5}>
        <SectionIntro
          title="PROFESSIONAL PEOPLE"
          subtitle="Meet the team"
          description="At Prism X LLC, our team excels in digital marketing, propelling your success in the online world."
        />
        <TestimonialSlider />
      </Box>
    </Box>
  );
};

export default Team;
