import { Grid, Typography, IconButton, Box } from "@mui/material";
import React from "react";
import Form from "../UI/Form";
import PinterestIcon from "@mui/icons-material/Pinterest";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CompanyContactData from "../Data/CompanyContactInfo";
import { SocialIcon } from "react-social-icons";

const ContactForm = () => {
  const { tiktok, pinterest, instagram, facebook } = CompanyContactData[0];

  return (
    <Grid
      container
      sx={{
        mb: 10,
        px: { md: 20, sm: 15, xs: 4 },
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          paddingRight: { xs: 0, md: 15 },
          textAlign: { xs: "center", md: "left" },
          my: { xs: 6, md: 0 },
        }}
      >
        <Typography
          sx={{
            color: "#1c9ac0",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "3px",
            textAlign: { xs: "center", md: "left" },
            fontFamily: "Barlow",
          }}
        >
          CONTACT US
        </Typography>
        <Typography
          sx={{
            fontSize: "46px",
            fontWeight: "800",
            lineHeight: "1.2",
            color: "#161c26",
            mb: "40px",
            fontFamily: "Barlow",
          }}
        >
          How Can We Assist You?
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            color: "grey",
            mb: "40px",
          }}
        >
          Reach out for support on listings, navigation, or any inquiries to
          enhance your Prism X journey.
        </Typography>
      </Grid>

      <Grid item xs={12} md={7}>
        <Form
          fields={[
            {
              type: "text",
              name: "first_name",
              required: true,
              placeholder: "Name",
            },
            {
              type: "email",
              name: "email",
              required: true,
              placeholder: "Email Address",
            },
            {
              type: "text",
              name: "subject",
              required: true,
              placeholder: "Subject",
            },
          ]}
          buttonText="Send Message"
          textAreaPlaceholder="Your Message"
        />
      </Grid>
    </Grid>
  );
};
export default ContactForm;
