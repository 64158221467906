import logo1 from ".././../assets/DummyListings/logo1.png";
import logo3 from ".././../assets/DummyListings/logo3.png";
import logo4 from ".././../assets/DummyListings/logo4.png";
import banner1 from ".././../assets/DummyListings/banner1.jpg";
import banner2 from ".././../assets/DummyListings/banner2.jpg";
import banner3 from ".././../assets/DummyListings/banner3.jpg";
import imagel1 from ".././../assets/DummyListings/imagel1.jpg";
import image1l1 from ".././../assets/DummyListings/image1l1.jpg";
import image2l1 from ".././../assets/DummyListings/image2l1.jpg";
import image3l1 from ".././../assets/DummyListings/image3l1.jpg";
import image4l1 from ".././../assets/DummyListings/image4l1.jpg";
import imagel2 from ".././../assets/DummyListings/imagel2.jpg";
import image1l2 from ".././../assets/DummyListings/image1l2.jpg";
import image2l2 from ".././../assets/DummyListings/image2l2.jpg";
import image3l2 from ".././../assets/DummyListings/image3l2.jpg";
import image4l2 from ".././../assets/DummyListings/image4l2.jpg";
import imagel3 from ".././../assets/DummyListings/imagel3.jpg";
import image1l3 from ".././../assets/DummyListings/image1l3.jpg";
import image2l3 from ".././../assets/DummyListings/image2l3.jpg";
import image3l3 from ".././../assets/DummyListings/image3l3.jpg";
import image4l3 from ".././../assets/DummyListings/image4l3.jpg";


const DummyListing = [
  {
    id: "19e73bf3-e61d-4fb7-bd70-2e04b6b309f9",
    creator: "2d39a7b0-2bea-43ca-8819-0f1d100271c7",
    title: "Timber Trails Flooring",
    desc: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    address: "Lorem ipsum dolor sit amet.",
    image: imagel1,
    slug: "timber-trails-flooring",
    phone: "(413)736-789",
    category: "Hardwood flooring",
    city: "Toronto",
    website: "timberfloor.net",
    priceRange: "Moderate",
    area: "LA street",
    image1: image1l1,
    image2: image2l1,
    image3: image3l1,
    image4: image4l1,
    intro:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.857389798219!2d-75.0095932!3d39.89982510000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6cb7e13e5f597%3A0xf7ceb96f2686b715!2s148%20Pearl%20Croft%20Rd%2C%20Cherry%20Hill%2C%20NJ%2008034%2C%20USA!5e0!3m2!1sen!2s!4v1720624180864!5m2!1sen!2s",
    businessLogo: logo1,
    businessBanner: banner1,
    region: "Canada",
    createdAt: "2024-07-05T16:06:15.000Z",
    updatedAt: "2024-07-08T14:37:03.000Z",
    aboutUs:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes",
    whyUs:
      "Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
    latestProjectIntro:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from by Cicero are also reproduced in their exact original form, accompanied by English versions from ",
    ourMission:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
    contactUsIntro:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage",
  },
  {
    id: "a2b3c4d5-e6f7-8g9h-1i2j-3k4l5m6n7o8p",
    creator: "3d49a8b1-3cea-54dc-992a-1f2d200382d8",
    title: "LuxHome Remodels",
    desc: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    address: "Lorem ipsum dolor sit amet.",
    image: imagel2,
    slug: "luxHome-remodels",
    phone: "(423)436-789",
    category: "Kitchen and Bathroom",
    city: "London",
    website: "luxhome.com",
    priceRange: "Expensive",
    area: "Cherry Hill",
    image1: image1l2,
    image2: image2l2,
    image3: image3l2,
    image4: image4l2,
    intro:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.857389798219!2d-75.0095932!3d39.89982510000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6cb7e13e5f597%3A0xf7ceb96f2686b715!2s148%20Pearl%20Croft%20Rd%2C%20Cherry%20Hill%2C%20NJ%2008034%2C%20USA!5e0!3m2!1sen!2s!4v1720624180864!5m2!1sen!2s",
    businessLogo: logo4,
    businessBanner: banner2,
    region: "UK",
    createdAt: "2024-07-06T16:06:15.000Z",
    updatedAt: "2024-07-09T14:37:03.000Z",
    aboutUs:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes",
    whyUs:
      "Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
    latestProjectIntro:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from by Cicero are also reproduced in their exact original form, accompanied by English versions from ",
    ourMission:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
    contactUsIntro:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage",
  },
  {
    id: "c3d4e5f6-g7h8-9i0j-1k2l-3m4n5o6p7q8r",
    creator: "4e59b9c2-4dfb-65ed-0c3b-2g3e310483e9",
    title: "PrimeFlow Plumbing",
    desc: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    address: "Lorem ipsum dolor sit amet.",
    image: imagel3,
    slug: "primeFlow-plumbing",
    phone: "(123)456-789",
    category: "Plumbing",
    city: "Seattle",
    website: "aquaFixplumbing.com",
    priceRange: "Affordable",
    area: "WA 98112",
    image1: image1l3,
    image2: image2l3,
    image3: image3l3,
    image4: image4l3,
    intro:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.857389798219!2d-75.0095932!3d39.89982510000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6cb7e13e5f597%3A0xf7ceb96f2686b715!2s148%20Pearl%20Croft%20Rd%2C%20Cherry%20Hill%2C%20NJ%2008034%2C%20USA!5e0!3m2!1sen!2s!4v1720624180864!5m2!1sen!2s",
    businessLogo: logo3,
    businessBanner: banner3,
    region: "USA",
    createdAt: "2024-07-07T16:06:15.000Z",
    updatedAt: "2024-07-10T14:37:03.000Z",
    aboutUs:
      "I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes",
    whyUs:
      "Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
    latestProjectIntro:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from  by Cicero are also reproduced in their exact original form, accompanied by English versions from ",
    ourMission:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
    contactUsIntro:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage",
  },
  
];

export default DummyListing;
